import React, { useCallback, useContext } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Query } from 'material-table';
import { Form, MTable } from '../../../../../Components';
import { FormValues } from '../../../../../Components/Form/Form.types';
import { useFormField } from '../../../../../Components/Form/FormField';
import { EditName } from '../EditName';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { INamedEntity, IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { getConnectedEntitiesString } from '../../../../../Utilities';
import { useStyles } from '../Organization.styles';

interface ITag extends INamedEntity {
  connections: {
    laws: number;
    requirements: number;
  };
}

function getDeleteDescription(
  entity: ITag,
  formatter: (descriptor: MessageDescriptor, values?: Record<string, string>) => string,
) {
  let connectionsString = getConnectedEntitiesString(
    {
      name: entity.name,
      laws: entity.connections.laws.toString(),
      requirements: entity.connections.requirements.toString(),
    },
    formatter,
  );
  connectionsString = connectionsString ? connectionsString + ' ' : '';
  return connectionsString + formatter({ id: 'organization.tags.delete.confirmation' });
}

const NewTagForm = (props: { unitId: number; onComplete: () => void }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const newTagField = useFormField({
    label: formatMessage({ id: 'organization.tags.addTag' }),
    name: 'name',
    type: 'text',
    required: true,
    hideRequiredAsterisk: true,
  });

  const handleAddTag = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.createTag(props.unitId, data);
    onSuccess();
    if (api.abortController?.signal.aborted) {
      return;
    }
    props.onComplete();
    newTagField.clear();
  };

  return (
    <Form
      className={classes.form}
      singleField={newTagField}
      submit={handleAddTag}
      submitLabel={formatMessage({ id: 'form.add' })}
    />
  );
};

export const TagList = (props: { unitId: number }) => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { deleteConfirmation } = useConfirmation();
  const { hasAccessToFeature } = useContext(UserContext);
  const canManage = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);

  const handleDataFetch = useCallback(
    async (query: Query<ITag>) => {
      return await api.searchTags<ITag>(props.unitId, {
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api, props.unitId],
  );

  const edit = (tag: ITag) => ({
    tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
    icon: 'edit',
    onClick: () => history.push(`/organization/company-units/${props.unitId}/tags/${tag.id}`),
  });

  const remove = (tag: ITag) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      const newRef: any = tableRef.current;
      const onRemove = async () => {
        await api.removeTag(props.unitId, tag.id);
        if (abortController.current.signal.aborted) {
          return;
        }
        newRef.onQueryChange();
      };
      deleteConfirmation(onRemove, { description: getDeleteDescription(tag, formatMessage) });
    },
  });

  const reloadTable = () => {
    (tableRef.current as any).onQueryChange();
  };

  return (
    <>
      {canManage && <NewTagForm unitId={props.unitId} onComplete={reloadTable} />}
      <MTable
        // hideToolbar
        tableRef={tableRef}
        columns={[{ title: formatMessage({ id: 'organization.tags' }), field: 'name' }]}
        data={handleDataFetch}
        actions={canManage ? [edit, remove] : undefined}
        components={{}}
      />
    </>
  );
};

export const EditTag = () => {
  const match = useRouteMatch<{ unitId: string; id: string }>();
  const unitId = parseInt(match.params.unitId);
  const { api } = useAptorApi();
  const history = useHistory();

  const fetchTag = useCallback((id: number) => api.getTag(unitId, id), [api, unitId]);
  const handleEditTag = useCallback(
    async (id: number, data: FormValues, api: AptorApi) => {
      await api.updateTag(unitId, id, data);
      history.push(`/organization/company-units/${unitId}`);
    },
    [history, unitId],
  );

  return (
    <EditName
      titleKey="organization.tags"
      backToLink={`/organization/company-units/${unitId}`}
      fetcher={fetchTag}
      submit={handleEditTag}
    />
  );
};
