import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Query } from 'material-table';
import { MTable, Container, RepealableName } from '../../../../../Components';
import { HtmlView } from '../../../../../Components/Html/Html.View';
import { OwnNotes } from '../../../../../Components/OwnNotes';
import { useFilter } from '../../../../../Components/ListFilter';
import { LawListFilter } from './CustomerLaws.List.Filter';
import { useAptorApi } from '../../../../../Api';
import { INamedEntity, IGridQueryRequest, OrderDirection } from '../../../../../Api/AptorApi';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { getConnectedEntitiesString } from '../../../../../Utilities';
import { Typography, Button, Tooltip } from '@material-ui/core';
import { CustomerLawListActions } from './CustomerLaws.List.Actions';
import { ArrowUpward } from '@material-ui/icons';
import { useStyles } from "../../../AdministrationModule.styles";
import { ModuleContext } from "../../../../../Context/ModuleContext/ModuleContext";
export interface ICustomerLaw extends INamedEntity {
  number: string;
  description: string;
  area: string;
  effectiveTo?: Date;
  companyUnits: Array<INamedEntity & { note: string | null }>;
  connections: {
    units: number;
    processes: number;
    aspects: number;
    tags: number;
  };
}

function getDeleteDescription(
  entity: ICustomerLaw,
  formatter: (descriptor: MessageDescriptor, values?: Record<string, string>) => string,
  getAlias: (formatMessage: (params: { id: string }) => string, lowercase?: boolean | undefined) => string,
) {
  let connectionsString = getConnectedEntitiesString(
    {
      name: entity.name,
      companyUnits: entity.connections.units.toString(),
      processes: entity.connections.processes.toString(),
      aspects: entity.connections.aspects.toString(),
      tags: entity.connections.tags.toString(),
    },
    formatter,
    getAlias,
  );
  connectionsString = connectionsString ? connectionsString + ' ' : '';
  return connectionsString + formatter({ id: 'law-portal.manage.law-list.table.delete.confirmation' });
}

export const CustomerLaws = () => {
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { deleteConfirmation } = useConfirmation();
  const { getCompanyUnitName, hasAccessToFeature } = useContext(UserContext);
  const canManage = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const [filters, setFilters] = useFilter();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classes = useStyles()
  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);

  useEffect(() => {

    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'area',
    };

    if (location.search?.split('&')?.length > 1) {
      Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
        const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilters(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
      });
    } else {
      let saveFilter: any = localStorage.getItem('org-law')
      const queryParams = new URLSearchParams(saveFilter);
      Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
        const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilters(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
      });

    }

  }, []);

  const handleDataFetch = useCallback(
    async (query: Query<ICustomerLaw>) => {
      return await api.searchCustomerLaws<ICustomerLaw>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const edit = (law: ICustomerLaw) => ({
    tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
    icon: 'edit',
    onClick: () => history.push(`/organization/law-portal/laws/${law.id}`),
  });

  const remove = (law: ICustomerLaw) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      const onRemove = async () => {
        await api.removeCustomerLaw(law.id);
        if (abortController.current.signal.aborted) {
          return;
        }
        tableRef.current && (tableRef.current as any).onQueryChange();
      };
      deleteConfirmation(onRemove, { description: getDeleteDescription(law, formatMessage, getCompanyUnitName) });
    },
  });

  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.manage.law-list', defaultMessage: 'Laws' }));
  }, [setHeader, formatMessage]);

  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    localStorage.setItem("org-law", updatedQueryParams)
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);

  return (
    <>
      {/* <Container fullWidth backButtonTo="/organization/law-portal"> */}
      <Container fullWidth >
        <CustomerLawListActions />
        <LawListFilter filters={filters} setFilter={setFilters} />
        <Typography style={{ whiteSpace: 'break-spaces' }} gutterBottom variant="body2">
          <FormattedMessage id="law-portal.manage.law-list.description" />
        </Typography>
        <MTable
          tableRef={tableRef}
          columns={[
            { title: formatMessage({ id: 'law-portal.manage.law-list.table.number' }), field: 'number' },
            {
              title: formatMessage({ id: 'law-portal.manage.law-list.table.law' }),
              field: 'name',
              render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
            },
            {
              title: formatMessage({ id: 'law-portal.manage.law-list.table.description' }),
              field: 'description',
              render: (row) => <HtmlView variant="body2" value={row.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.manage.law-list.table.area' }), field: 'area' },
            {
              title: formatMessage({ id: 'law-portal.manage.law-list.table.notes' }),
              field: 'notes',
              render: (row) => <OwnNotes {...row} linkTo={`/law-portal/our-laws/${row.id}`} />,
              sorting: false,
            },
          ]}
          data={handleDataFetch}
          actions={canManage ? [edit, remove] : undefined}
          components={{}}
        />
      </Container>
      {showScrollTop && (
        <Tooltip title={formatMessage({ id: "bottom.to.top.btn.tooltip.text", defaultMessage: "Back to top" })}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
