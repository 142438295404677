import { 
  // Grid,
   IconButton,
    InputBase,
    //  TextField 
    } from "@material-ui/core";
import { CloseOutlined, Search } from "@material-ui/icons";
import { useStyles } from "./Field.styles";
import { useIntl } from "react-intl";
import React from "react";
type searchProps = {
  onChange: (str: string) => void;
  clearSearch?: () => void;
  value: any;
};
const SearchTextField = ({ onChange, value, clearSearch }:searchProps) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.searchRoot}
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        <Search className={classes.searchIcon} />
        <InputBase
          placeholder={formatMessage({
            id: "component.mtable.searchPlaceholder",
            defaultMessage: "Search",
          })}
          onChange={(event) => onChange(event.target.value)}
          value={value}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
        <IconButton
          onClick={clearSearch}
          style={{ position: "absolute", right: 0 }}
        >
          <CloseOutlined className={classes.closeSearch} />
        </IconButton>
      </div>
    </>
  );
};
export default SearchTextField;
