import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { List, Grid, Button } from '@material-ui/core';
import { Loading, Panel, Container, BackButton } from '../../../../../../../Components';
import { LawQuestionGroup, CustomerQuestions } from './Checklist.Group/Checklist.Group';
import { LawQuestion } from './Checklist.Question/Checklist.Question';
import { CustomerQuestionForm } from './Checklist.Form';
import { AptorApi, useAptorApi } from '../../../../../../../Api';
import { PropertyError } from '../../../../../../../Api/AptorApi';
import { useApiValidationSnackbar } from '../../../../../../../Api/useValidationSnackbar';
import { useList } from '../../../../../../../Hooks/useList';
import { useChecklist } from '../../../../../../../Hooks/useChecklist';
import { useStyles } from '../../../../../../AptorAdmin/Components/Checklist/Checklist.List/Checklist.List.styles';

interface IChecklist {
  law: string;
  items: Array<IQuestion | IGroup>;
  companyUnitQuestions: ICustomerQuestion[];
}

interface ICustomerQuestion {
  id?: number;
  name: string;
}

interface IQuestion {
  id: number;
  name: string;
  type: 'question';
  hidden: boolean;
}

interface IGroup {
  id: number;
  name: string;
  type: 'group';
  groupItems: IQuestion[];
}

export const EditOrganizationLawPortalChecklist = () => {
  const match = useRouteMatch<{ lawid: string; unitid: string }>();
  const lawid = parseInt(match.params.lawid);
  const unitid = parseInt(match.params.unitid);
  const history = useHistory();
  const { api, abortController } = useAptorApi();
  const { notifySuccess, notifyUnhandledException, notifyValidationErrors } = useApiValidationSnackbar();
  const [law, setLaw] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const classes = useStyles();

  const [
    companyUnitQuestions,
    loadCompanyUnitQuestionsQuestions,
    addCustomerQuestion,
    ,
    deleteCustomerQuestion,
  ] = useList<ICustomerQuestion>();
  const [questions, loadQuestions, updateQuestion] = useChecklist<IQuestion, IGroup>();

  useEffect(() => {
    api.getCustomerLawChecklist<IChecklist>(lawid, unitid).then((checklist) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setLaw(checklist.law);
      loadQuestions(checklist.items);
      loadCompanyUnitQuestionsQuestions(checklist.companyUnitQuestions);
      setLoaded(true);
    });
  }, [api, abortController, lawid, unitid, loadCompanyUnitQuestionsQuestions, loadQuestions]);

  const submit = async () => {
    const hiddenQuestions = questions.filter((i) => i.type === 'question' && i.hidden).map((i) => i.id);
    const hiddenGroupQuestions = questions
      .filter((i) => i.type === 'group')
      .map((i) => (i as IGroup).groupItems)
      .reduce((a, b) => a.concat(b), [])
      .filter((i) => i.hidden)
      .map((i) => i.id);
    const request = {
      hiddenQuestions: hiddenQuestions.concat(hiddenGroupQuestions),
      companyUnitQuestions: companyUnitQuestions,
    };

    const action = (i: AptorApi) => i.updateCustomerLawChecklist(lawid, unitid, request).then(() => notifySuccess());
    const onValidation = (errors: PropertyError[]) => notifyValidationErrors(errors);
    await api.invoke(action, abortController.current, onValidation, notifyUnhandledException);
    if (abortController.current.signal.aborted) {
      return;
    }

    history.push(`/organization/law-portal/laws/${lawid}/company-units/${unitid}`);
  };

  const onToggleHidden = useCallback(
    (questionId: number, groupId: number | undefined, hidden: boolean) => {
      updateQuestion(questionId, groupId, { hidden });
    },
    [updateQuestion],
  );
  if (!loaded) {
    return <Loading />;
  }
  return (
    <Container>
      <BackButton link={`/organization/law-portal/laws/${lawid}/company-units/${unitid}`} />
      <Panel titleKey="admin.law-portal.law.checklist.heading" titleValues={{ name: law }}>
        <List className={classes.root}>
          {questions.map((item:any, index) =>
            item.type.toLowerCase() === 'question' ? (
              <LawQuestion key={index} {...item} index={index} toggleHidden={onToggleHidden} />
            ) : (
              <LawQuestionGroup key={index} {...item} toggleHidden={onToggleHidden} />
            ),
          )}
          {companyUnitQuestions.length > 0 && (
            <CustomerQuestions
              key="customer-questions"
              questions={companyUnitQuestions}
              loadCompanyUnitQuestionsQuestions={loadCompanyUnitQuestionsQuestions}
              remove={deleteCustomerQuestion}
            />
          )}
        </List>
        <CustomerQuestionForm add={(name) => addCustomerQuestion({ name })} />
        <Grid container justify="flex-end">
          <Button variant="outlined" onClick={submit}>
            <FormattedMessage id="form.save" />
          </Button>
        </Grid>
      </Panel>
    </Container>
  );
};
