// import { IAppConfiguration } from './IAppConfiguration';

// export default {
//   logrocketKey: undefined,
//   aptorApi: 'https://aptoronline.b2clogin.com/aptoronline.onmicrosoft.com/B2C_1A_signup_signin',
//   clientId: 'c09591bc-b2bf-4cad-862f-6cd5152f045e',
//   tenantName: 'aptoronline',
//   tinyMCEApiKey: '6zgxiaak6g8avn6bwl9rgosil3mhb0cfud1dfrm0k0p09ldv',
//   redirectUrl: 'http://localhost:3000/',
//   policyName: 'B2C_1_signup_signin',
//   inivtePolicy: 'B2C_1A_signup_invitation',
//   forgotPasswordPolicy: 'B2C_1_forgot_password',
//   redirectLogoutUrl: 'http://localhost:3000/',
//   tenantId: '4cf894e2-2dde-41ca-865c-b4f8e0e4717a',
//   enableImpersonation: true,
//   knownAuthority: 'https://aptoronline.b2clogin.com',
//   scopes: ['https://aptoronline.onmicrosoft.com/c09591bc-b2bf-4cad-862f-6cd5152f045e/user_impersonation'],
// } as IAppConfiguration;
import { IAppConfiguration } from './IAppConfiguration';
export default {
  logrocketKey: undefined,
  aptorApi: 'https://api.lagportalen.se',
  clientId: 'f8b57dc0-cd2f-4bca-b550-f34cc4218060',
  tenantName: 'aptoronline',
  tinyMCEApiKey: '6zgxiaak6g8avn6bwl9rgosil3mhb0cfud1dfrm0k0p09ldv',
  redirectUrl: 'https://lagportalen.se/',
  policyName: 'B2C_1A_signup_signin',
  inivtePolicy: 'B2C_1A_signup_invitation',
  forgotPasswordPolicy: 'B2C_1A_passwordreset',
  redirectLogoutUrl: 'https://lagportalen.se/',
  tenantId: '4cf894e2-2dde-41ca-865c-b4f8e0e4717a',
  enableImpersonation: true,
  knownAuthority: 'https://aptoronline.b2clogin.com',
  scopes: ['https://aptoronline.onmicrosoft.com/bb5d7358-b61e-46c4-8333-ff25d0e904ba/user_impersonation'],
} as IAppConfiguration;