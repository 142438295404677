import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { getAppConfiguration } from '../Config';
const hash = window.location.hash;
const forgotPasswordKey = 'userForgotPassword';
const containsIdTokenHint = hash.indexOf('id_token_hint=') >= 0;
const cancelledForgotPassword = hash.indexOf('AADB2C90091') >= 0;
// let idTokenHint = undefined as any;
// if (containsIdTokenHint) {
//   idTokenHint = hash
//     .replace('#', '')
//     .split('&')
//     .find((seg) => seg.indexOf('id_token_hint=') >= 0);
// }

const containsIdToken = hash.indexOf('id_token=') >= 0;
const forgotPassword =
  !containsIdToken &&
  !cancelledForgotPassword &&
  (localStorage.getItem(forgotPasswordKey) === '1' || hash.indexOf('AADB2C90118') >= 0);
let authProvider: any;
export const getAuthProvider = () => {
  if (authProvider) {
    return authProvider;
  }
  const appConfig = getAppConfiguration();
  const config = {
    auth: {
      clientId: appConfig.clientId,
      authority: `https://${appConfig.tenantName}.b2clogin.com/tfp/${appConfig.tenantName}.onmicrosoft.com/${
        containsIdTokenHint
          ? appConfig.inivtePolicy
          : forgotPassword
          ? appConfig.forgotPasswordPolicy
          : appConfig.policyName
      }`,
      knownAuthorities: [appConfig.knownAuthority],
      redirectUri: appConfig.redirectUrl,
      postLogoutRedirectUri: appConfig.redirectLogoutUrl,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };
  if (!authProvider) {
    authProvider = new PublicClientApplication(config);
  }

  return authProvider;
};
export const loginRedirect = {
  scopes: getAppConfiguration().scopes,
};
