import React, { useCallback, useContext } from 'react';
import clsx from 'clsx';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard } from './Features/Dashboard';
import { OurLaws } from './Features/OurLaws';
import { OtherRequirements } from './Features/OtherRequirements';
import { Statistics } from './Features/Statistics';
import { Settings } from './Features/Settings/Settings';
import { Grid } from '@material-ui/core';
import { LawPortalSidebar } from './Components/LawPortalSidebar';
import { ModuleContext, useModule } from '../../Context/ModuleContext/ModuleContext';
import { Header } from '../../Components/Header/Header';
import { useStyles } from '../Module.styles';
import { AccessLevel, LawPortalFeature, UserContext } from '../../Context/UserContext/UserContext';
import { LegalComplianceControl } from './Features/LegalComplianceControl';
import { ComplianceControlRequirement } from './Features/ComplainceControlReqiurement';
import { Administration } from './Features/Administration';
import { CreateLawComplianceReport } from "./Features/OurLaws/Law/Compliance/Create";
import { Help } from "./Features/Help";

export const LawPortalModule = () => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { hasAccessToFeature } = useContext(UserContext);
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);

  const handleSidebarToggle = useCallback((open: boolean) => {
    setSidebarOpen(open);
  }, []);
  const { Provider } = ModuleContext;
  const module = useModule();

  return (
    <Grid container>
      <Grid item>
        <LawPortalSidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle} />
      </Grid>
      <Grid
        item
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <Provider value={module}>
          <Header
            sidebarOpen={sidebarOpen}
            handleSidebarToggle={handleSidebarToggle}
            title={module.header}
            subMenu={module.subMenu}
          />
          <Switch>
            <Route exact path="/law-portal/dashboard" component={Dashboard} />
            {accessToOurLaws && <Route path="/law-portal/our-laws" component={OurLaws} />}
            {accessToOtherRequirements && <Route path="/law-portal/other-requirements" component={OtherRequirements} />}
            <Route path="/law-portal/administration" component={Administration} />
            <Route path="/law-portal/statistics" component={Statistics} />
            <Route path="/law-portal/legal-compliance-control" component={LegalComplianceControl} />
            <Route path="/law-portal/compliance-control-requirements" component={ComplianceControlRequirement} />
            <Route path="/law-portal/settings" component={Settings} />
            <Route path="/law-portal/help" component={Help} />
            <Route path="/law-portal/legal-compliance-control/84/laws/report" component={CreateLawComplianceReport} />
            <Route path="/law-portal">
              <Redirect to="/law-portal/dashboard" />
            </Route>
          </Switch>
        </Provider>
      </Grid>
    </Grid>
  );
};
export default LawPortalModule;
