import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '../../../../Components';
import { FormValues } from '../../../../Components/Form/Form.types';
import { useFormField } from '../../../../Components/Form/FormField';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { AptorApi } from '../../../../Api';
import { INamedEntity } from '../../../../Api/AptorApi';
import { entityToSelectOption } from '../../../../Utilities';

interface IProps {
  value?: INamedEntity & { note: string | null };
  companyUnits: INamedEntity[];
  submit: (companyUnitId: number, note: string, api: AptorApi, afterSubmit: () => void) => Promise<void>;
  cancel?: () => void;
}

export const NotesForm: FC<IProps> = ({ value, companyUnits, submit, cancel }) => {
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);

  const companyUnitField = useFormField({
    type: 'single-select',
    name: 'companyUnit',
    required: true,
    label: getCompanyUnitName(formatMessage),
    options: companyUnits.map(entityToSelectOption),
    initialState: value ? entityToSelectOption(value) : undefined,
  });

  const noteField = useFormField({
    type: 'rich-text',
    name: 'note',
    required: true,
    label: formatMessage({ id: 'law-portal.overview-notes.form.note.label.info' }),
    initialState: value?.note ? { value: value.note } : undefined,
  });

  const onSubmit = async (data: FormValues, api: AptorApi, afterSubmit: () => void) => {
    companyUnitField.clear();
    noteField.clear();
    const note = data['note'] as string;
    const unit = data['companyUnit'] as number;
    await submit(unit, note, api, afterSubmit);
  };

  return (
    <Form
      groups={[{ grid: { type: 'column', items: [companyUnitField, noteField] } }]}
      submit={onSubmit}
      cancel={cancel}
      submitLabel={formatMessage({ id: value ? 'form.save' : 'form.add' })}
    />
  );
};
