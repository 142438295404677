import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './Result.style';
import { FormattedMessage, useIntl } from 'react-intl';

interface ResultProps {
    complied:number;
    deficiency:number;
    deviation:number;
    fulfilled:number;
  }
export const ResultCompliance = ({complied, deficiency, deviation, fulfilled}:ResultProps) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
  return (
    <Grid container justify="space-between">
      <Grid>
        <Typography variant="h5" component="h3">
          {formatMessage({id:'law-portal.compliance.result'})}
        </Typography>
      </Grid>
      <Grid container xs={12} sm={5} justify='space-between'>
      <Grid>
        <Typography variant="h6" component="h3" className={classes.yes}>
        {formatMessage({id:'law-portal.compliance.complied'})} : {complied}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="h6" component="h3" className={classes.deficiency}>
        {formatMessage({id:'law-portal.compliance.deficiency'})} : {deficiency}
        </Typography>
      </Grid>
      <Grid>
        <Typography variant="h6" component="h3" className={classes.no}>
        {formatMessage({id:'law-portal.compliance.deviation'})} : {deviation}
        </Typography>
      </Grid>
      </Grid>
      <Grid>
        <Typography variant="h6" component="h3">
        <FormattedMessage
          id="law-portal.compliance.fulfilled"
          values={{fulfilled}}
        />
        </Typography>
      </Grid>
    </Grid>
  );
};
