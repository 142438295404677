import React from 'react'
import { MTable, RepealableName } from "../../../../../../Components";
import { useIntl } from "react-intl";
import { Button, LinearProgress, Theme, createStyles, withStyles } from "@material-ui/core";

interface BorderLinearProgressProps {
    name: string; // Define the type of the name prop
    variant: 'determinate' | 'indeterminate' | 'buffer' | 'query';
    value: number;
}

const PlanningViewKEK = () => {
    const tableRef = React.createRef();
    let { formatMessage } = useIntl();
    const BorderLinearProgress = withStyles((theme: Theme) =>
        createStyles({
            root: {
                height: 25,
                borderRadius: 10,
                boderWidth: '3px',
                borderColor: 'red',
                borderStyle: 'solid',
            },
            colorPrimary: {
                backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            },
            bar: {
                borderRadius: 10,
                // Use the name prop to set the background color of the bar
                backgroundColor: (props: BorderLinearProgressProps) =>
                    props.name === 'blue' ? theme.palette.grey[theme.palette.type === 'light' ? 200 : 700] : 'green',
            },
        }),
    )(({ name, ...props }: BorderLinearProgressProps) => <LinearProgress {...props} />);

    return (
        <>
            <div style={{ paddingTop: '10px', paddingLeft: '30px', paddingRight: '30px' }}>
                <MTable
                    tableRef={tableRef}
                    columns={[
                        { title: formatMessage({ id: 'law-portal.manage.law-list.table.number' }), field: 'number' },
                        {
                            title: formatMessage({ id: 'law-portal.manage.law-list.table.law' }),
                            field: 'name',
                            render: (row: any) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
                        },
                        {
                            title: formatMessage({ id: 'law-portal.complince.direct.table.header.status' }),
                            field: 'status',
                            render: (row) => <BorderLinearProgress variant="determinate" value={50} name="blue" />,
                        },
                        {
                            title: formatMessage({ id: 'law-portal.compliance.tab.carryout.table.user' }),
                            field: 'userName',
                        },
                        {
                            title: '',
                            field: '',
                            render: (row) => {
                                return (
                                    <>
                                        <Button
                                            variant="contained"
                                        >
                                            {formatMessage({ id: "law-portal.our-laws.compliance.tab.carryout.kek.create.button" })}
                                        </Button>
                                        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '7px' }}>
                                <Tooltip title={formatMessage({id:"law-portal.our-law.compliance.complete"})}>
                                  <DoneOutline />
                                </Tooltip>
                                <Tooltip title={formatMessage({id:"law-portal.our-law.compliance.edit"})}>
                                <Edit />
                                </Tooltip>
                              </div> */}
                                    </>
                                );
                            },
                        },
                    ]}
                    hideToolbar
                    data={[{ name: 'dnkd' }]}
                    actionLabel={''}
                    components={{}}
                />
            </div>
        </>
    )
}

export default PlanningViewKEK