import React, { useEffect, useContext,
  //  useState
   } from 'react';
import { useIntl } from 'react-intl';
import { 
  // Button,
   Grid,
    // Tooltip
   } from '@material-ui/core';
import { Panel, Container } from '../../../../Components';
import { HelpPanel } from './HelpPanel';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
// import { ArrowUpward } from '@material-ui/icons';
export const Help = () => {
  const { setHeader } = useContext(ModuleContext);
  const { formatMessage } = useIntl();
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.help', defaultMessage: 'Settings' }));
  }, [setHeader, formatMessage]);
  return (
    <>
      <Container>
        <Grid container spacing={4} direction="column" justify="space-evenly">
          <Grid item>
            <Panel titleKey="law-portal.settings.help" accordion defaultExpanded={true}>
              <HelpPanel />
            </Panel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
