import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(20),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  container: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
    dragging: {
    backgroundColor: theme.palette.action.disabledBackground, // Change the background color when dragging
  },

}));
