import React, { useContext } from 'react';
import { Sidebar, ISidebarProps } from '../../../../Components/Sidebar/Sidebar';
import {
  Gavel,
  Business,
  LiveHelp,
  //  Dashboard,
  FormatListBulleted,
  Dashboard
} from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, Module, UserContext } from '../../../../Context/UserContext/UserContext';

export const AdministrationSidebar = (props: ISidebarProps) => {
  const { formatMessage } = useIntl();
  const { hasAccessToFeature, hasAccessToModule } = useContext(UserContext);
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);
  const items = [];

  if (hasAccessToModule(Module.Organization)) {
    items.push({
      to: '/organization',
      text: formatMessage({ id: 'organization.header', defaultMessage: 'Organization' }),
      icon: <Business />,
    });
  }
  if (hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage)) {
    // items.push({
    //   to: '/organization/law-portal',
    //   text: formatMessage({ id: 'law-portal.manage.header', defaultMessage: 'Laws and other requirements' }),
    //   icon: <Gavel />,
    // });
    if (hasAccessToModule(Module.LawPortal)) {
      if (accessToOurLaws) {
        items.push({
          to: '/organization/law-portal/laws',
          text: formatMessage({ id: 'law-portal.manage.law-list', defaultMessage: 'Laws' }),
          icon: <Gavel />,
        });
      }

      if (accessToOtherRequirements) {
        items.push({
          to: '/organization/law-portal/other-requirements',
          text: formatMessage({ id: 'law-portal.manage.requirements', defaultMessage: 'other requirements' }),
          icon: <FormatListBulleted />,
        });
      }

    }
  }

  items.push({
    to: '/law-portal/dashboard',
    text: formatMessage({ id: 'law-portal.dashboard.header', defaultMessage: 'Dashboard' }),
    icon: <Dashboard />,
  });
  // if (hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage)) {
  //   items.push({
  //     to: '/organization/law-portal',
  //     text: formatMessage({ id: 'law-portal.manage.header', defaultMessage: 'Laws and other requirements' }),
  //     icon: <Dashboard />,
  //   });
  // }
  items.push({
    to: '/organization/help',
    text: formatMessage({ id: 'law-portal.help', defaultMessage: 'Help' }),
    icon: <LiveHelp />,
  })
  // {hasAccessToModule(Module.LawPortal) && (
  //   <Grid container spacing={2} justify="center">
  //     {accessToOurLaws && (
  //       <CardLink
  //         link="/organization/law-portal/laws"
  //         label="law-portal.manage.law-list"
  //         image={lagportalenImg}
  //       />
  //     )}
  //     {accessToOtherRequirements && (
  //       <CardLink
  //         link="/organization/law-portal/other-requirements"
  //         label="law-portal.manage.requirements"
  //         image={lagportalenImg}
  //       />
  //     )}
  //   </Grid>
  // )}
  return (
    <Sidebar
      title={formatMessage({ id: 'component.home.administration', defaultMessage: 'Administration' })}
      items={items}
      sidebarOpen={props.sidebarOpen}
      handleSidebarToggle={props.handleSidebarToggle}
    />
  );
};
