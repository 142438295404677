import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form } from '../../../../Components/Form';
import { AptorApi, useAptorApi } from '../../../../Api';
import { useIntl } from 'react-intl';
import { useFormField } from '../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../Components/Form/Form.types';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { OptionsType } from 'react-select';
import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
interface IProp {
  setAnchorEl?: (() => void) | undefined
}
export const General = ({ setAnchorEl }: IProp) => {
  const intl = useIntl();
  const { api, abortController } = useAptorApi();
  const { mailSettings, language, tableRowCount, fetchUser } = useContext(UserContext);
  const [availableLanguages, setAvailableLanguages] = useState<OptionsType<SelectOption>>([]);
  const [availableMailSettings, setAvailableMailSettings] = useState<OptionsType<SelectOption>>([]);
  const formatMessage = useCallback(
    (id: string) => {
      return intl.formatMessage({ id: id, defaultMessage: id });
    },
    [intl],
  );

  const languageField = useFormField({
    label: formatMessage('form.selectLanguage'),
    name: 'language',
    type: 'single-select',
    placeholder: formatMessage('form.selectLanguage'),
    options: availableLanguages,
    initialState: { value: language.value, label: language.displayName },
    required: true,
    hideRequiredAsterisk: true,
  });

  const mailSettingsField = useFormField({
    label: formatMessage('form.mailSettings'),
    name: 'mailSettings',
    type: 'single-select',
    placeholder: formatMessage('form.selectMailSettings'),
    options: availableMailSettings,
    initialState: { value: mailSettings.value, label: mailSettings.displayName },
    required: false,
    icon: <Tooltip
    title={formatMessage("law-portal.profile.mailing.icon.text")}
    placement="right-start"
  >
    <InfoOutlined style={{ fontSize: "20px" }} />
  </Tooltip>
  });

  const setMailSettings = mailSettingsField.setValue;
  const setLanguage = languageField.setValue;

  useEffect(() => {
    setMailSettings({ value: mailSettings.value, label: mailSettings.displayName });
    setLanguage({ value: language.value, label: language.displayName });
  }, [setMailSettings, setLanguage, mailSettings, language]);

  useEffect(() => {
    const fetchAvailableLanguages = async () => {
      const result = await api.getLanguages();
      const languages = result.items.map((l) => ({
        value: l.id,
        label: l.name,
      }));

      setAvailableLanguages(languages);
    };

    const fetchAvailableMailSettings = async () => {
      const result = await api.getMailSettings();
      const mailSettings = result.items.map((l) => ({
        value: l.id,
        label: l.name,
      }));

      setAvailableMailSettings(mailSettings);
    };

    fetchAvailableLanguages();
    fetchAvailableMailSettings();
  }, [formatMessage, api, abortController]);

  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: () => void) => {
    await api.updateSettings(data);
    onSuccess();
    if (api.abortController?.signal.aborted) {
      return;
    }
    if (setAnchorEl) {
      setAnchorEl()
    }
    await fetchUser();
  };
  const RowNumberField = useFormField({
    label: formatMessage('form.numberofrow'),
    name: 'tableRowCount',
    type: 'single-select',
    placeholder: formatMessage('form.selectnumberofrow'),
    options: [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 20, label: '20' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
    ],
    initialState: { value: tableRowCount, label: tableRowCount.toString() },
    required: true,
    hideRequiredAsterisk: true,
    icon: <Tooltip
      title={formatMessage('number-of-law-info')}
      placement="right-start"
    >
      <InfoOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  });
  return (
    <>
      <Form
        style={{ width: '300px' }}
        submit={handleSubmit}
        groups={[
          {
            grid: {
              type: 'column',

              items: [
                { type: 'row', items: [languageField] },
                { type: 'row', items: [RowNumberField] },
                { items: [mailSettingsField], type: 'row' },
              ],
            },
          },
        ]}
      />
    </>
  );
};
