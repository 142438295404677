import React from 'react';
import clsx from 'clsx';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { ArrowBackIos } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useStyles } from './BackButton.styles';

interface IBackButtonProps {
  link: string;
  disableMargin?: boolean;
  onclick?:()=>void;
}

export const BackButton = (props: IBackButtonProps) => {
  const { link, disableMargin ,onclick} = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();
  return (
    onclick ?
      <ButtonLink
        className={clsx({ [classes.margin]: !disableMargin })}
        to={link}
        onclick={onclick}
        text={formatMessage({ id: 'component.back-button.back', defaultMessage: 'Back' })}
        icon={<ArrowBackIos />}
        variant="text"
      />
      :
      <ButtonLink
        className={clsx({ [classes.margin]: !disableMargin })}
        to={link}
        text={formatMessage({ id: 'component.back-button.back', defaultMessage: 'Back' })}
        icon={<ArrowBackIos />}
        variant="text"
      />
  );
};
