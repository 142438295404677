import React, { useState, forwardRef, useImperativeHandle, createRef, useCallback } from 'react';
import TextField from './TextField';
import { useIntl } from 'react-intl';
import { FormFieldRef, NumberFieldProps } from './Field.props';
import { TypedFormFieldValue } from '../Form/Form.types';

const isNumberValid = (numstr: string | undefined, required?: boolean, isInt?: boolean) => {
  if (!required && (numstr === undefined || numstr.length === 0)) {
    return true;
  }
  let isNumeric = numstr !== undefined && !isNaN(+numstr);
  if (isInt) return isNumeric && Number.isInteger(+numstr!);
  return isNumeric;
};
export const NumberField = forwardRef<FormFieldRef<number>, NumberFieldProps>((props, ref) => {
  const intl = useIntl();
  const { required, isInt, onChange, ...rest } = props;
  const [isValid, setValid] = useState<boolean>(false);

  const textFieldRef = createRef<FormFieldRef<string>>();
  useImperativeHandle(ref, () => ({
    setValue: (value: TypedFormFieldValue<number> | TypedFormFieldValue<number>[] | undefined) => {
      if (!Array.isArray(value) && textFieldRef.current) {
        textFieldRef.current.setValue(value?.value !== undefined ? { value: `${value.value}` } : undefined);
      }
    },
  }));

  const getErrorTexts = () => {
    return !isValid ? [intl.formatMessage({ id: 'form.wrongFormat' }), ...props.errorTexts] : props.errorTexts;
  };

  const handleChange = useCallback(
    (field: TypedFormFieldValue<string> | undefined) => {
      const isValid = isNumberValid(field?.value, required, isInt);
      setValid(isValid);

      if (onChange) {
        if (field?.value === '' || (field === undefined && !required)) {
          onChange(undefined);
        } else {
          onChange({ value: field === undefined ? NaN : +field?.value, isValid: isValid });
        }
      }
    },
    [required, isInt, onChange],
  );

  return (
    <TextField
      {...rest}
      required={required}
      initialState={
        (props.initialState&&(props.initialState as TypedFormFieldValue<number>)?.value!=null && {
          ...props.initialState,
          value: (props.initialState as TypedFormFieldValue<number>).value.toString(),
        }) ||
        undefined
      }
      ref={textFieldRef}
      error={props.error || !isValid}
      errorTexts={getErrorTexts()}
      onChange={handleChange}
      type={isInt ? 'number' : 'text'}
    />
  );
});

export default NumberField;
