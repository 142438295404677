import { useState, useEffect, useCallback } from 'react';
import { ILawListFilters } from './AptorApi';
import { mapEntityListToOptions, OptionsEntity } from '../Utilities';

export function useFetchFilterOptions<T extends OptionsEntity>(
  fetchFunc: () => Promise<ILawListFilters>,
  abortController: React.MutableRefObject<AbortController>,
): [T | null, React.Dispatch<React.SetStateAction<T | null>>, () => void, { [userId: number]: string[] }] {
  const [initFlag, setInitFlag] = useState(false);
  const [options, setOptions] = useState<T | null>(null);
  const [userCompanyUnits, setUserCompanyUnits] = useState<{ [userId: number]: string[] }>({});
  useEffect(() => {
    const fetchOptions = async () => {
      const result = await fetchFunc();
      if (abortController.current.signal.aborted) {
        return;
      }
      // setInitFlag(true);

      const users: { [userId: number]: string[] } = {};
      result.complianceUsers.forEach((companyUnit) => {
        companyUnit.entities?.forEach((user) => {
          if (users[user.id]) {
            users[user.id].push(companyUnit.name);
          } else {
            users[user.id] = [companyUnit.name];
          }
        });
      });

      setUserCompanyUnits(users);

      const mappedResult = {
        ...result,
        complianceUsers: result.complianceUsers
          .flatMap((companyUnit) => companyUnit.entities)
          .filter((user, index, array) => array.findIndex((x) => x.id === user.id) === index), // Make a flat list of unique users
      };

      setOptions(mapEntityListToOptions(mappedResult) as T);
    };

    if (!initFlag) {
      fetchOptions();
    }
  }, [initFlag, fetchFunc, abortController]);

  const refetchOptions = useCallback(() => {
    setInitFlag(false);
  }, []);

  return [options, setOptions, refetchOptions, userCompanyUnits];
}
