import { useCallback, useEffect } from 'react';
import { useStateWithLocalStorage } from '../../../../../Hooks/useStateWithStorage';
import { SelectOption } from '../../../../../Components/Form/Form.types';
import { OptionsType } from 'react-select';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

export type compliance = 'yes' | 'deficiency' | 'no';

interface IAnswer {
  compliance?: compliance;
  comment?: string;
}

export interface IQuestion extends IAnswer {
  id: number;
  name: string;
  type: 'question';
  hidden: boolean;
}

interface IGroup {
  id: number;
  name: string;
  type: 'group';
  groupItems: IQuestion[];
}

interface IChecklistState {
  items: Array<IQuestion | IGroup>;
  companyUnitQuestions: IQuestion[];
  date?: Date;
  companyUnit?: number;
  otherParticipants?: (string | number)[];
  summary?: string;
  comment?: string;
}

interface ILoadStoreStateParams {
  items: Array<IQuestion | IGroup>;
  companyUnitQuestions: IQuestion[];
}

export const useReportSavedState = (
  companyUnits: OptionsType<SelectOption>,
  fetchComplianceUsers: (companyUnitId: number) => Promise<OptionsType<SelectOption>>,
  setDate: (value: { value: Date }) => void,
  setCompanyUnit: (value: { value: number; label: string }) => void,
  setOtherParticipants: (value: { value: string | number; label: string }[]) => void,
  setSummary: (value: { value: string }) => void,
  storageKey: string,
  setComment:(value:{value:string})=>void
) => {
  const [, storeState, initialState] = useStateWithLocalStorage<IChecklistState>(storageKey);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const setState = (question: IQuestion | undefined, storedState: IQuestion) => {
    if (question) {
      question.comment = storedState.comment;
      question.compliance = storedState.compliance;
      question.hidden = storedState.hidden;
    }
  };

  useEffect(() => {
    if (initialState && initialState.companyUnit) {
      const match = companyUnits.find((x) => x.value === initialState.companyUnit);
      if (match) {
        setCompanyUnit({ value: match.value as number, label: match.label });
      }
    }
  }, [initialState, companyUnits, setCompanyUnit]);

  useEffect(() => {
    if (initialState && initialState.date) {
      setDate({ value: initialState.date });
    }
  }, [initialState, setDate]);

  useEffect(() => {
    const updateParticipants = async (initialState: IChecklistState) => {
      const users = await fetchComplianceUsers(initialState.companyUnit!);
      const participants = initialState.otherParticipants!.map((participant) => {
        if (typeof participant === 'string') {
          return { value: participant, label: participant };
        } else {
          var match = users.find((x) => +x.value === participant);
          if (match) {
            return { value: +match.value, label: match.label };
          } else {
            return { value: participant, label: `${participant}` };
          }
        }
      });
      setOtherParticipants(participants);
    }
    if (initialState && initialState.otherParticipants && initialState.companyUnit) {
      updateParticipants(initialState);
    }
  }, [initialState, fetchComplianceUsers, setOtherParticipants]);

  useEffect(() => {
    if (initialState && initialState.summary) {
      setSummary({ value: initialState.summary });
    }
  }, [initialState, setSummary]);
  useEffect(() => {
    if (initialState && initialState.comment) {
      setComment({ value: initialState.comment });
    }
  }, [initialState, setComment]);

  const updateState = useCallback(
    async (state: ILoadStoreStateParams) => {
      if (initialState) {
        enqueueSnackbar(formatMessage({ id: 'law-portal.compliance.state.loaded' }), { variant: 'info' });
        initialState.items.forEach((item:any) => {
          if (item.type.toLowerCase() === 'question') {
            const match = state.items.find((i) => item.id === i.id) as IQuestion;
            setState(match, item);
          } else {
            const match = state.items.find((i) => item.id === i.id) as IGroup;
            if (match) {
              item.groupItems.forEach((groupItem:any) => {
                const groupItemMatch = match.groupItems.find((i) => i.id === groupItem.id);
                setState(groupItemMatch, groupItem);
              });
            }
          }
        });

        initialState.companyUnitQuestions.forEach((item) => {
          const match = state.companyUnitQuestions.find((i) => i.id === item.id);
          setState(match, item);
        });
      }
    },
    [
      initialState,
      enqueueSnackbar,
      formatMessage,
    ],
  );

  const clearState = useCallback(() => storeState(undefined), [storeState]);

  return { updateState, storeState, clearState };
};
