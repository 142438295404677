import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { RequirementList } from './List';
import {
  RequirementOverview,
  RequirementChanges,
  // RequirementCompliance,
  RequirementInternalComments,
} from './Requirement';
import { Container } from '../../../../Components';
import { useAptorApi } from '../../../../Api';
import { useIntl } from 'react-intl';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../Context/UserContext/UserContext';
import { ChangesToAcknowledge } from "./Unacknowledged";
import { AcknowledgedChanges } from "./Acknowledged";

interface IRequirementInfo {
  name: string;
}

const Requirement = ({ info, id }: { id: number; info: IRequirementInfo }) => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);

  useEffect(() => {
    const header = info ? info.name : '';
    const subMenu = [
      {
        title: formatMessage({ id: 'law-portal.requirements.sub-menu.overview' }),
        to: `/law-portal/other-requirements/${id}`,
      },
      {
        title: formatMessage({ id: 'law-portal.requirements.sub-menu.changes' }),
        to: `/law-portal/other-requirements/${id}/changes`,
      },
      // {
      //   title: formatMessage({ id: 'law-portal.requirements.sub-menu.compliance' }),
      //   to: `/law-portal/other-requirements/${id}/compliance`,
      // },
    ];
    setHeader(header, subMenu);
  }, [id, info, setHeader, formatMessage]);

  return (
    <Switch>
      <Route path="/law-portal/other-requirements/:id(\d+)" exact component={RequirementOverview} />
      <Route path="/law-portal/other-requirements/:id(\d+)/changes" component={RequirementChanges} />
      {/* <Route path="/law-portal/other-requirements/:id(\d+)/compliance" component={RequirementCompliance} /> */}
    </Switch>
  );
};

const RequirementView = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [info, setInfo] = useState<IRequirementInfo>();

  useEffect(() => {
    api.getLawPortalRequirementInfo<IRequirementInfo>(id).then((result) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setInfo(result);
    });
  }, [api, abortController, id]);

  const user = useContext(UserContext);

  const isManager = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const isComplianceUser = user.complianceUserFor.requirements.some((x) => x.id === id);

  if (info === undefined) {
    return <></>;
  }

  if (!isManager && !isComplianceUser) {
    return <Redirect to="/law-portal/other-requirements" />;
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Requirement info={info} id={id} />
      <RequirementInternalComments id={id} />
    </Grid>
  );
};

export const OtherRequirements = () => {
  const { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other requirements' }), [
      {
        title: formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other requirements' }),
        to: `/law-portal/other-requirements`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.unacknowledged' }),
        to: `/law-portal/other-requirements/unacknowledged`,
      },
      {
        title: formatMessage({ id: 'law-portal.statistics.sub-menu.changes.acknowledged' }),
        to: `/law-portal/other-requirements/acknowledged`,
      },

    ]);
  }, [setHeader, formatMessage]);
  return (
    <Container fullWidth>
      <Switch>
        <Route exact path="/law-portal/other-requirements" component={RequirementList} />
        <Route path="/law-portal/other-requirements/:id(\d+)" component={RequirementView} />
        <Route path="/law-portal/other-requirements/unacknowledged" component={ChangesToAcknowledge} />
          <Route path="/law-portal/other-requirements/acknowledged" component={AcknowledgedChanges} />
      </Switch>
    </Container>
  );
};
