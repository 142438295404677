import React, {
  useContext,
  //  useEffect
} from 'react';
import { Switch, Route } from 'react-router-dom';
// import { FormattedMessage, useIntl} from 'react-intl';
// import { Grid, Typography } from '@material-ui/core';
// import { Container } from '../../../../Components';
// import { CardLink } from '../../../../Components/Home/Components/CardLink/CardLink';
// import lagportalenImg from '../../../../Assets/Images/lagportalen.png';
import { CustomerLaws } from './CustomerLaws/CustomerLaws.List';
import { AddCustomerLaws } from './CustomerLaws/CustomerLaws.Add';
import { EditCustomerLaw } from './CustomerLaws/CustomerLaw.Edit';
import { UpdateCustomerLaws } from './CustomerLaws/CustomerLaws.Update';
import { AddLawCompanyUnit, EditLawCompanyUnit } from './CustomerLaws/CompanyUnits/LawCompanyUnits.Edit';
import { EditOrganizationLawPortalChecklist } from './CustomerLaws/CompanyUnits/Checklist/Checklist.Edit';
import { OtherRequirements } from './OtherRequirements/CustomerRequirements.List';
import { NewRequirement } from './OtherRequirements/CustomerRequirement.Create';
import { EditRequirement } from './OtherRequirements/CustomerRequirement.Edit';
import {
  AddRequirementCompanyUnit,
  EditRequirementCompanyUnit,
} from './OtherRequirements/CompanyUnits/RequirementCompanyUnits.Edit';
import { EditRequirementChecklist } from './OtherRequirements/CompanyUnits/Checklist';
// import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import {
  UserContext,
  LawPortalFeature,
  AccessLevel,
  //  Module 
} from '../../../../Context/UserContext/UserContext';

export const LawPortal = () => {
  // const { formatMessage } = useIntl();
  // const { setHeader } = useContext(ModuleContext);
  const {
    // hasAccessToModule,
    hasAccessToFeature } = useContext(UserContext);
  const canManage = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);

  // useEffect(() => {
  //   setHeader(formatMessage({ id: 'law-portal.manage.header', defaultMessage: 'Laws and other requirements' }));
  // }, [setHeader, formatMessage]);

  return (
    <Switch>
      {/* <Route exact path="/organization/law-portal">
        <Container>
          {hasAccessToModule(Module.LawPortal) && (
            <Grid container spacing={2} justify="center">
              {accessToOurLaws && (
                <CardLink
                  link="/organization/law-portal/laws"
                  label="law-portal.manage.law-list"
                  image={lagportalenImg}
                />
              )}
              {accessToOtherRequirements && (
                <CardLink
                  link="/organization/law-portal/other-requirements"
                  label="law-portal.manage.requirements"
                  image={lagportalenImg}
                />
              )}
            </Grid>
          )}
          {!hasAccessToModule(Module.LawPortal) && (
            <Typography component="h2" variant="h3">
              <FormattedMessage id="law-portal.manage.noAccess" />
            </Typography>
          )}
        </Container>
      </Route> */}
      {canManage && accessToOurLaws && (
        <Route path="/organization/law-portal/laws/:lawid(\d+)/company-units/new" component={AddLawCompanyUnit} />
      )}
      {canManage && accessToOurLaws && (
        <Route
          path="/organization/law-portal/laws/:lawid(\d+)/company-units/:unitid(\d+)/checklist"
          component={EditOrganizationLawPortalChecklist}
        />
      )}
      {canManage && accessToOurLaws && (
        <Route
          path="/organization/law-portal/laws/:lawid(\d+)/company-units/:unitid(\d+)"
          component={EditLawCompanyUnit}
        />
      )}
      {canManage && accessToOurLaws && <Route path="/organization/law-portal/laws/new" component={AddCustomerLaws} />}
      {accessToOurLaws && (
        <Route
          path="/organization/law-portal/laws/update-for-company-unit/:unitid(\d+)"
          component={UpdateCustomerLaws}
        />
      )}
      {canManage && accessToOurLaws && (
        <Route path="/organization/law-portal/laws/:lawid(\d+)" component={EditCustomerLaw} />
      )}
      {accessToOurLaws && <Route path="/organization/law-portal/laws" component={CustomerLaws} />}

      {canManage && accessToOtherRequirements && (
        <Route
          path="/organization/law-portal/other-requirements/:reqid(\d+)/company-units/new"
          component={AddRequirementCompanyUnit}
        />
      )}
      {canManage && accessToOtherRequirements && (
        <Route
          path="/organization/law-portal/other-requirements/:reqid(\d+)/company-units/:unitid(\d+)/checklist"
          component={EditRequirementChecklist}
        />
      )}
      {canManage && accessToOtherRequirements && (
        <Route
          path="/organization/law-portal/other-requirements/:reqid(\d+)/company-units/:unitid(\d+)"
          component={EditRequirementCompanyUnit}
        />
      )}
      {canManage && accessToOtherRequirements && (
        <Route path="/organization/law-portal/other-requirements/new" component={NewRequirement} />
      )}
      {canManage && accessToOtherRequirements && (
        <Route path="/organization/law-portal/other-requirements/:reqid(\d+)" component={EditRequirement} />
      )}
      {accessToOtherRequirements && (
        <Route path="/organization/law-portal/other-requirements" component={OtherRequirements} />
      )}
    </Switch>
  );
};
