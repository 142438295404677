import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Grid,Button,Tooltip } from '@material-ui/core';
import { HtmlView, Panel, BackButton } from '../../../../../../Components';
// import { RequirementComplianceInterval } from './ComplianceInterval';
import { ICompliance, ComplianceUsers } from '../../../../Components/Compliance/ComplianceUsers';
import { IRequirementChange, LatestChange } from '../Changes';
import { RequirementNotes } from './RequirementNotes';
import { IGridQueryRequest, INamedEntity } from '../../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../../Api';
import { useStyles } from "../../../../LawPortal.styles";
import {ArrowUpward} from '@material-ui/icons'
import { useIntl } from "react-intl";

export interface ICompanyUnitRequirement extends INamedEntity {
  hasNextComplianceInterval: boolean;
  note: string | null;
}

interface IState {
  shortDescription: string;
  latestChange?: IRequirementChange;
  companyUnits: Array<ICompanyUnitRequirement>;
  internalComments: Array<{ from: string; comment: string; createdAt: Date }>;
}

export const RequirementOverview = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const [overview, setOverview] = useState<IState>();
  const classes=useStyles()
  const history=useHistory()
const {formatMessage}=useIntl()
  useEffect(() => {
    api.getLawPortalRequirementOverview<IState>(id).then((overview) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setOverview(overview);
    });
  }, [api, abortController, id]);

  const updateState = useCallback(
    (state: Partial<IState>) => overview && setOverview({ ...overview, ...state }),
    [overview],
  );

  const dataFetcher = useCallback(
    (request: IGridQueryRequest) => api.searchRequirementComplianceUsers<ICompliance>(id, request),
    [id, api],
  );

  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (overview === undefined) {
    return <></>;
  }
  return (
    <>
      <Grid item xs={12}>
        {/* <BackButton disableMargin link="/law-portal/other-requirements" /> */}
        <BackButton disableMargin link="" onclick={()=>history.goBack()} />
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Panel titleKey="law-portal.requirements.overview.short-description">
              <HtmlView value={overview.shortDescription} />
            </Panel>
          </Grid>
          {/* <Grid item xs={12}>
            <RequirementComplianceInterval reqId={id} companyUnits={overview.companyUnits} />
          </Grid> */}
          {overview.latestChange && (
            <Grid item xs={12}>
              <LatestChange reqId={id} change={overview.latestChange} onChange={updateState} />
            </Grid>
          )}
          <Grid item xs={12}>
            <RequirementNotes reqId={id} companyUnits={overview.companyUnits} onChange={updateState} />
          </Grid>
          <Grid item xs={12}>
            <ComplianceUsers dataFetcher={dataFetcher} />
          </Grid>
        </Grid>
      </Grid>
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}

    </>
  );
};
