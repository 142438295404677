import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CreateComplianceReport, IReport, IChecklist } from '../../../../Components/Compliance/CreateReport';
import { SelectOption } from '../../../../../../Components/Form/Form.types';
import { ComplianceSessionInformation } from './SessionInformation';
import { AptorApi, useAptorApi } from '../../../../../../Api';
import { entityToSelectOption } from '../../../../../../Utilities';
import { useComplianceSession } from '../../../../../../Context/ComplianceSessionContext';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { Grid } from '@material-ui/core';
import { BackButton } from '../../../../../../Components';

export const CreateLawComplianceReport = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const history = useHistory();
  const { hasAccessToFeature } = useContext(UserContext);
  const [loadingChecklist, setLoadingChecklist] = useState<boolean>(true);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [companyUnits, setCompanyUnits] = useState<SelectOption[]>([]);
  const [companyUnitId, setCompanyUnitId] = useState<number>();

  const [checklist, setChecklist] = useState<IChecklist>();
  const { complianceSession, completeLawCompliance } = useComplianceSession();

  useEffect(() => {
    if (companyUnitId !== undefined) {
      setLoadingChecklist(true);
      api.getLawPortalLawChecklist<IChecklist>(id, companyUnitId).then((chkl) => {
        if (!abortController.current.signal.aborted) {
          setChecklist(chkl);
          setLoadingChecklist(false);
        }
      });
    } else {
      api.getUserCompanyUnitsForLawCompliance(id).then((userCompanyUnits) => {
        if (!abortController.current.signal.aborted) {
          setCompanyUnits(userCompanyUnits.items.map(entityToSelectOption));
          setLoaded(true);
        }
      });
    }
  }, [api, abortController, companyUnitId, id]);

  const submitAction = (unitId: number,reportId:any, a: AptorApi, report: IReport) =>
    a.createLawPortalLawComplianceReport(id, unitId, report);

  const onCreateSuccess = () => {
    if (complianceSession) {
      completeLawCompliance(id);
      const nextLaw = complianceSession.next();
      if (nextLaw) {
        history.push(nextLaw.complianceUrl);
      } else {
        history.push('/law-portal/our-laws');
      }
    } else {
      history.push(`/law-portal/our-laws/${id}/compliance`);
    }
  };

  const handleFetchComplianceUsers = useCallback(
    async (companyUnitId: number) => {
      const result = await api.getParticipantsForLawCompliance(id, companyUnitId);
      return result.items.map(entityToSelectOption);
    },
    [api, id],
  );

  return (
    <>
      <Grid item xs={12}>
        <BackButton disableMargin link={`/law-portal/our-laws/${id}/compliance`} />
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container direction="column" spacing={2} >
          <ComplianceSessionInformation id={id} />
          <Grid item >
            <CreateComplianceReport
              key={`law-${id}`}
              lawId={id}
              titleKey="law-portal.our-laws.compliance.heading"
              summaryKey="law-portal.our-laws.compliance.summary.label"
              storageKey={`compliance-state-for-law-${id}`}
              loaded={loaded}
              loadingChecklist={loadingChecklist}
              units={companyUnits}
              setLoaded={setLoaded}
              fetchComplianceUsers={handleFetchComplianceUsers}
              onCompanyUnitChange={setCompanyUnitId}
              checklist={checklist}
              disableHide={!hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage)}
              submitAction={submitAction}
              onCreateSuccess={onCreateSuccess}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
