import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  yes: {
    color: theme.palette.success.main,
    fontWeight:'bold'
  },
  deficiency: {
    color: theme.palette.warning.main,
    fontWeight:'bold'
  },
  no: {
    color: theme.palette.error.main,
    fontWeight:'bold'
  },
}));
