import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { useFormField } from '../../../../../Components/Form/FormField';
import { FormValues, SelectOption } from '../../../../../Components/Form/Form.types';
import { AptorApi, useAptorApi, useFetchFilterOptions } from '../../../../../Api';
import { Form, Panel } from '../../../../../Components';
// import { useStyles } from './CreateDirectCompliance.style';
import { ComplianceTable } from './List/ComplianceTable';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../Context/UserContext/UserContext';
import { OptionsEntity, entityToSelectOption } from '../../../../../Utilities';

interface IOptions extends OptionsEntity {
  areas: SelectOption[];
  categories: SelectOption[];
  companyUnits: SelectOption[];
  complianceUsers: SelectOption[];
  statuses: SelectOption[];
  processes: SelectOption[];
  aspects: SelectOption[];
  tags: SelectOption[];
}
interface IProps {
  getComplianceDirectPlan: () => void;
  setcreateVisible: any;
  handleAllControl:()=>void;
}

export const CreateDirectCompliance = ({ getComplianceDirectPlan,handleAllControl, setcreateVisible }: IProps) => {
  const { formatMessage } = useIntl();
  const { companyUnits, customerId ,hasAccessToFeature} = useContext(UserContext);
  const { api, abortController } = useAptorApi();
  // const [directPlanID, setDirectPlanID] = useState('');
  const [type, setType] = useState("")
  const [allOptions, , ,
    //  userCompanyUnits
    ] = useFetchFilterOptions<IOptions>(
    useCallback(() => api.getLawPortalLawFilters(), [api]),
    abortController,
  );
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);
  // let typeField=[
  //   {id:0,name:formatMessage({ id: "law-portal.plan.type.law",defaultMessage:'Legislation'}) },
  //   {id:1,name:formatMessage({ id: "law-portal.plan.type.other-req",defaultMessage:'Other requirements' })}
  // ]
  const typeField: any = []
  if (accessToOurLaws) {
    typeField.push({ id: 0, name: formatMessage({ id: "law-portal.plan.type.law", defaultMessage: 'Legislation' }) })
  }
  if (accessToOtherRequirements) {
    typeField.push({ id: 1, name: formatMessage({ id: "law-portal.plan.type.other-req", defaultMessage: 'Other requirements' }) })
  }
  const [options, setOptions] = useState<IOptions | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  useEffect(() => {
    setOptions(allOptions);
  }, [allOptions]);
  useEffect(()=>{
    if (accessToOurLaws && accessToOtherRequirements) {
      setType('')
    } else if (accessToOtherRequirements) {
      const typeData: any = typeField[typeField?.findIndex((val:any)=>val.id===1)]
      setType(typeData)
    } else if (accessToOurLaws) {
      const typeData: any = typeField[typeField?.findIndex((val:any)=>val.id===0)]
      setType(typeData)
    }
  },[])
  // const classes = useStyles();
  const namePlanning = useFormField({
    label: formatMessage({ id: 'form.name.lek' }),
    required: true,
    name: 'name',
    type: 'text',
  });

  const companyUnit = useFormField({
    label: formatMessage({ id: 'form.compliance.selectCompanyUnits' }),
    name: 'CustomerCompanyUnitId',
    type: 'single-select',
    required: true,
    options: companyUnits.map(entityToSelectOption),
  });
  const managerName = useFormField({
    label: formatMessage({ id: 'form.name.manager' }),
    name: 'responsible',
    type: 'single-select',
    required: true,
    options: options?.complianceUsers || [],
    placeholder: formatMessage({ id: 'law-portal.statistics.table.user' }),
  });
  const startDate = useFormField({
    label: formatMessage({ id: 'form.startDate' }),
    name: 'startDate',
    type: 'date',
    required: true,
  });
  const planType = useFormField({
    type: 'single-select',
    name: 'type',
    required: true,
    label: formatMessage({ id: 'law-portal.create-plan.form-field-type' }),
    options: typeField.map(entityToSelectOption),
    initialState: accessToOtherRequirements&&accessToOurLaws?undefined:accessToOurLaws?entityToSelectOption(typeField[typeField?.findIndex((val:any)=>val.id===0)]):accessToOtherRequirements?entityToSelectOption(typeField[typeField?.findIndex((val:any)=>val.id===1)]):undefined,
    checkedValue(value) {
      setType(value)
    },
    disable: accessToOurLaws&&accessToOtherRequirements?false:accessToOurLaws?true:accessToOtherRequirements?true:false
  });
  const handleSubmit = async (data: FormValues, api: AptorApi, onSuccess: (key: string) => void) => {
    data.isDirect = true;
    let cusId: number | null = customerId;
    await api.createCompliancePlan(cusId, data).then((response: any) => {
      api.addLaws(response.id, {Legislations:selectedRows,type:data.type}).then(() => {
        setcreateVisible(false);
        getComplianceDirectPlan();
        handleAllControl()
        onSuccess('law-portal.complince.create-direct-plan.sucess.msg');
      });
    });
  };
  return (
    <>
      <Panel>
        <Typography>
          {formatMessage({ id: 'law-portal.legal.complaince.create.new.compliance.header.text' })}
        </Typography>
        <Form
          submit={handleSubmit}
          submitLabel={formatMessage({ id: 'form.save' })}
          cancelLabel={formatMessage({ id: 'form.send' })}
          hideButton={selectedRows.length === 0 ? true : false}
          groups={[
            {
              grid: {
                type: 'column',
                items: [
                  { type: 'row', items: [namePlanning, companyUnit, managerName] },
                  { type: 'row', items: [startDate,planType, null, null] }
                ],
              },
            },
          ]}
        />
      </Panel>
      {type!==""&&<ComplianceTable selectedRows={selectedRows} type={type} setSelectedRows={setSelectedRows} />}
    </>
  );
};
