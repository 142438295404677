import React, { FC } from 'react';
import clsx from 'clsx';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  // Button,
  Grid,
  // IconButton,
} from '@material-ui/core';
import { Accordion } from '@material-ui/core'
import { ExpandMore, InfoOutlined } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStyles } from './Panel.styles';

interface ITitleProps {
  title?: string;
  titleKey?: string;
  titleValues?: any;
}

interface IProps extends ITitleProps {
  autoHeight?: boolean;
  accordion?: boolean;
  actionIcon?: React.ReactNode;
  defaultExpanded?: boolean;
  buttonIcon?: React.ReactNode;
}

export const Title: FC<ITitleProps> = ({ title, titleKey, titleValues }) => {
  const { formatMessage } = useIntl();
  if (title || titleKey) {
    return (
      <Typography variant="h3" component="h2" style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
        {title || <FormattedMessage id={titleKey} values={titleValues} />}
        {titleKey === 'law-portal.our-laws.changes.latest-change.label'  && (
          <Tooltip
            title={formatMessage({
              id: 'law-portal.our-laws.changes.latest-change.information',
              defaultMessage: 'The change seen in this section is the latest published change. Note the effective date of the change. All changes to the legislation can be found under Historical changes.',
            })}
            placement="right-start"
          >
            <InfoOutlined />
          </Tooltip>
        )}
         {titleKey === 'law-portal.requirements.changes.latest-change.label'  && (
          <Tooltip
            title={formatMessage({
              id: 'law-portal.our-requirement.changes.latest-change.information',
              defaultMessage: 'The change seen in this section is the latest published change. Note the effective date of the change. All changes to the requirement can be found under Historical changes.',
            })}
            placement="right-start"
          >
            <InfoOutlined />
          </Tooltip>
        )}
      </Typography>
    );
  }
  return null;
};

export const Panel: FC<IProps> = ({
  autoHeight,
  defaultExpanded,
  title,
  titleKey,
  children,
  accordion,
  actionIcon,
  titleValues,
  buttonIcon,
}) => {
  const classes = useStyles();

  return (
    (accordion && (
      <ExpansionPanel defaultExpanded={defaultExpanded}>
        <ExpansionPanelSummary expandIcon={actionIcon ?? <ExpandMore />}>
          <Grid container justify='space-between' alignItems='center'>
            <Title title={title} titleKey={titleKey} titleValues={titleValues} />
            {buttonIcon && (
              <Grid>
                {buttonIcon}
              </Grid>
            )}
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    )) || (
      <Card className={clsx(classes.root, { [classes.autoHeight]: autoHeight })}>
        {(title || titleKey || actionIcon) && (
          <CardHeader
            title={<Title title={title} titleKey={titleKey} titleValues={titleValues} />}

            action={actionIcon}
          />
        )}
        <CardContent>{children}</CardContent>
      </Card>
    )
  );
};
