import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from '../../../LawPortal.styles';
import {
  ButtonLink,
  // MTable,
  // Panel,
  // RepealableName,
  Transition
} from '../../../../../Components';
import { useIntl } from 'react-intl';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  //  IconButton,
  // LinearProgress,
  // Theme,
  // Tooltip,
  //  Typography,
  // createStyles,
  // withStyles
} from '@material-ui/core';
import {
  ArrowRightAlt,
  //  ControlPointDuplicate,
  // Delete,
  //  DoneOutline,
  // Edit,
  ExpandMore,
  // PlaylistAdd,
  //  SyncDisabledSharp,
  // SyncSharp
} from '@material-ui/icons';
import { CreateDirectCompliance } from "./CreateDirectCompliance";
import { ModuleContext } from '../../../../../Context/ModuleContext/ModuleContext';
import { PlanFeature, UserContext } from "../../../../../Context/UserContext/UserContext";
import { useAptorApi } from "../../../../../Api";
import { Title } from "../../../../../Components/Panel/Panel";
import moment from "moment";
import { useComplianceState } from "./List/ComplianceState";
// import async from "react-select/async";
import PlanningViewKEK from "./View/PlanningViewKEK";
// import { PublishedWithChanges, Unpublished } from '@mui/icons-material';
interface IconProps {
  id: number;
  icon?: React.ReactNode;
  onClick?: () => void;
}
interface BorderLinearProgressProps {
  name: string; // Define the type of the name prop
  variant: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value: number;
}
export const CarryKEK = () => {
  const {
    // enableCompliance,
    // complianceEnabled,
    // disableCompliance,
    startComplianceSession,
    // hasCompliance,
    // isComplianceUserFor,
    // toggleCompliance,
    setLawsSelectedForCompliance
  } = useComplianceState();
  // const tableRef = React.createRef();
  const classes = useStyles();
  const [createvisible, setcreateVisible] = useState(false);
  const { customerId } = useContext(UserContext)
  const { api, abortController } = useAptorApi();
  const [directPlanLoading, setDirectPlanLoading] = useState(false)
  const [planData, setPlanData] = useState([])
  const [directPlan, setDirectPlanData] = useState([])
  const [expendDirectPlanPanel, setExpendDirectPlanPanel] = useState("")
  const [expendPlanPanel, setExpendPlanPanel] = useState("")
  let { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.legal.requirement.header', defaultMessage: 'Compliance control requirement' }), [
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.kek' }),
        to: `/law-portal/compliance-control-requirements/planning`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.kek' }),
        to: `/law-portal/compliance-control-requirements/carry`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.kek' }),
        to: `/law-portal/compliance-control-requirements/reports`,
      },
    ]);
  }, [setHeader, formatMessage]);
  // const editPlane = () => {
  //   alert('edit plane');
  // };
  const [startCompliance, setStartCompliance] = useState(false)
  const getplanLaws = async (planData: any) => {
    await api.searchdirectPlanLaws(planData.planID.id).then((response: any) => {
      setLawsSelectedForCompliance(response)
      setStartCompliance(true)
    })
  }
  const handleComplianceSession = async (event: any, planData: any) => {
    event.stopPropagation();
    await getplanLaws(planData)
    setStartCompliance(false)
  }
  useEffect(() => {
    startComplianceSession()
  }, [startCompliance])

  const IconsNode = (planData: any) => {
    return (
      <Grid>
        <Button variant="contained" type="submit" onClick={(event: any) => handleComplianceSession(event, planData)} >
          <ArrowRightAlt />
          {formatMessage({ id: "law-portal.compliance.tab.carryout.lek.panel.btn.name" })}
        </Button>
      </Grid>
    );
  };

  // const BorderLinearProgress = withStyles((theme: Theme) =>
  //   createStyles({
  //     root: {
  //       height: 25,
  //       borderRadius: 10,
  //       boderWidth: '3px',
  //       borderColor: 'red',
  //       borderStyle: 'solid',
  //     },
  //     colorPrimary: {
  //       backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  //     },
  //     bar: {
  //       borderRadius: 10,
  //       // Use the name prop to set the background color of the bar
  //       backgroundColor: (props: BorderLinearProgressProps) =>
  //         props.name === 'blue' ? theme.palette.grey[theme.palette.type === 'light' ? 200 : 700] : 'green',
  //     },
  //   }),
  // )(({ name, ...props }: BorderLinearProgressProps) => <LinearProgress {...props} />);
  useEffect(() => {
    getComplianceDirectPlan()
  }, [])
  const getComplianceDirectPlan = async () => {
    setDirectPlanLoading(true);
    await api.getAllcompliancePlan(customerId,{}).then((response: any) => {
      if (!abortController.current.signal.aborted) {
        const plan = response?.customerPlans?.filter((val: any) => !val.isDirect && val.type === PlanFeature.requirement)
        const directPlan = response?.customerPlans?.filter((val: any) => val.isDirect && val.type === PlanFeature.requirement)
        setPlanData(plan)
        setDirectPlanData(directPlan)
        setDirectPlanLoading(false);
      }
    }).catch(() => {
      setDirectPlanLoading(false);
    })
  }
  const handleDirectPlanPanelToggle = (panelId: string) => {
    // Toggle the expansion state of the panel with the given panelId.
    if (expendDirectPlanPanel === panelId) {
      setExpendDirectPlanPanel("");
    } else {
      setExpendDirectPlanPanel(panelId);
    }
  };
  const handlePlanPanelToggle = (panelId: string) => {
    // Toggle the expansion state of the panel with the given panelId.
    if (expendPlanPanel === panelId) {
      setExpendPlanPanel("");
    } else {
      setExpendPlanPanel(panelId);
    }
  };
  return (
    <div className={classes.listWrapper}>
      {createvisible ?
        <ButtonLink text={formatMessage({ id: "component.back-button.back" })} to={''} onclick={() => setcreateVisible(false)} />
        :
        <ButtonLink text={formatMessage({ id: "law-portal.legal.complaince.create.new.compliance.kek.direct" })} to={''} onclick={() => setcreateVisible(true)} />
      }
      <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
      {createvisible ?
        <CreateDirectCompliance getComplianceDirectPlan={getComplianceDirectPlan} setcreateVisible={setcreateVisible} />
        :
        <>
          <Transition isLoading={directPlanLoading}>
            <Grid container spacing={4} direction="column" justify="space-evenly">
              <Grid>
                <h2 style={{ marginLeft: 20 }}>
                  {formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.continuously' })}
                </h2>
                <h3 style={{ marginTop: 0, marginLeft: 20 }}>{`${formatMessage({
                  id: 'utils.filters.status',
                })}: ${10} ${formatMessage({ id: 'text.of' })} ${15} ${formatMessage({ id: 'status.text' })} `}</h3>
              </Grid>
              <Grid style={{ display: "flex", flexDirection: "column", gap: '15px' }} item>
                {
                  planData?.map((val: any, index: number) => {
                    return (
                      <div key={index}>
                        <ExpansionPanel defaultExpanded={false} expanded={expendPlanPanel === val.id} onChange={() => handlePlanPanelToggle(val.id)} >
                          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Grid container justifyContent='space-between' alignItems='center'>
                              <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                              <Grid>
                                {val.lawCount > 0 && <IconsNode planID={val} />}
                              </Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className={classes.panelDetails}>
                            {expendPlanPanel === val.id && <PlanningViewKEK />}
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    )
                  })
                }
              </Grid>
            </Grid>
            <hr style={{ marginTop: '25px', marginBottom: '25px' }} />
            <Grid container spacing={4} direction="column" justify="space-evenly">
              <Grid>
                <h2 style={{ marginLeft: 20 }}>
                  {formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.ongoing' })}
                </h2>
                <h3 style={{ marginTop: 0, marginLeft: 20 }}>{`${formatMessage({
                  id: 'utils.filters.status',
                })}: ${10} ${formatMessage({ id: 'text.of' })} ${15} ${formatMessage({ id: 'status.text' })} `}</h3>
              </Grid>
              <Grid style={{ display: "flex", flexDirection: "column", gap: '15px' }} item>
                {
                  directPlan?.map((val: any, index: number) => {
                    return (
                      <div key={index}>
                        <ExpansionPanel defaultExpanded={false} expanded={expendDirectPlanPanel === val.id} onChange={() => handleDirectPlanPanelToggle(val.id)} >
                          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Grid container justifyContent='space-between' alignItems='center'>
                              <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${val.endDate ? moment(val.endDate).format("YYYY-MM-DD") : ""} ${val.name}` })} />
                              <Grid>
                                {val.lawCount > 0 && <IconsNode planID={val} />}
                              </Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className={classes.panelDetails}>
                            {expendDirectPlanPanel === val.id && <PlanningViewKEK />}
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Transition>
        </>
      }
    </div>
  );
}
