import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ListFilter,
  // createDateFilter,
  createMultiSelectFilter,
  createSelectFilter,
} from '../../../../../Components/ListFilter';
import { SelectOption } from '../../../../../Components/Form/Form.types';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useAptorApi, useFetchFilterOptions } from '../../../../../Api';
import { OptionsEntity } from '../../../../../Utilities';
import { updateFilterOptionsDependentOnCompanyUnit } from '../../../../../Components/ListFilter/filterUtils';

interface IOptions extends OptionsEntity {
  areas: SelectOption[];
  categories: SelectOption[];
  companyUnits: SelectOption[];
  complianceUsers: SelectOption[];
  processes: SelectOption[];
  aspects: SelectOption[];
  tags: SelectOption[];
  search:SelectOption[];
}

interface IProps {
  filters: any;
  setFilter: (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => void;
}

export const ReportFilter: FC<IProps> = ({ filters, setFilter, children }) => {
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { getCompanyUnitName } = useContext(UserContext);
  const [allOptions, , , userCompanyUnits] = useFetchFilterOptions<IOptions>(
    useCallback(() => api.getLawPortalLawFilters(), [api]),
    abortController,
  );

  const [options, setOptions] = useState<IOptions | null>(null);

  useEffect(() => {
    setOptions(allOptions);
  }, [allOptions]);
  const onSetFilter = (field: string, values: string[] | { min?: Date; max?: Date } | undefined) => {
    setFilter(field, values);
    updateFilterOptionsDependentOnCompanyUnit(
      field,
      values,
      setOptions,
      allOptions,
      filters,
      setFilter,
      userCompanyUnits,
    );
  };

  const areaFilter = createSelectFilter(
    'area',
    formatMessage({ id: 'utils.filters.area' }),
    options?.areas,
    (option) => option.value.toString(),
    undefined,
    filters.area,
  );

  const categoryFilter = createSelectFilter(
    'category',
    formatMessage({ id: 'utils.filters.category' }),
    options?.categories,
    (option) => option.value.toString(),
    undefined,
    filters.category,
  );

  const companyUnitFilter = createSelectFilter(
    'companyUnit',
    getCompanyUnitName(formatMessage),
    options?.companyUnits,
    (option) => option.value.toString(),
    undefined,
    filters.companyUnit,
  );

  const complianceUsersFilter = createMultiSelectFilter(
    'complianceUsers',
    formatMessage({ id: 'utils.filters.complianceUsers' }),
    options?.complianceUsers,
    (option) => option.value.toString(),
    undefined,
    filters.complianceUsers as string[],
  );

  const activeOption = [
    { value: 1, label: formatMessage({ id: 'utils.filters.status.active' }) },
    { value: 2, label: 'test' },
    { value: 3, label: 'tets2' },
    { value: 4, label: 'test3'},
  ];


  const processesFilter = createMultiSelectFilter(
    'processes',
    formatMessage({ id: 'utils.filters.processes' }),
    options?.processes,
    (option) => option.value.toString(),
    undefined,
    filters.processes as string[],
  );

  const aspectsFilter = createMultiSelectFilter(
    'aspects',
    formatMessage({ id: 'utils.filters.aspects' }),
    options?.aspects,
    (option) => option.value.toString(),
    undefined,
    filters.aspects as string[],
  );

  const tagsFilter = createMultiSelectFilter(
    'tags',
    formatMessage({ id: 'utils.filters.tags' }),
    options?.tags,
    (option) => option.value.toString(),
    undefined,
    filters.tags as string[],
  );
// const searchFilter = createMultiSelectFilter(
//   'search',
//   formatMessage({ id: 'component.mtable.searchPlaceholder' }),
//     options?.search,
//     (option) => option.value.toString(),
//     undefined,
//     filters.search as string[],
// )
const filterSearch = createSelectFilter(
  'filterSearch',
  formatMessage({ id: 'component.mtable.searchPlaceholder' }),
  activeOption
)
  // const complianceIntervalFilter = createDateFilter(
  //   'nextComplianceInterval',
  //   formatMessage({ id: 'utils.filters.nextLawComplianceInterval.from' }),
  //   formatMessage({ id: 'utils.filters.nextLawComplianceInterval.to' }),
  // );

  return (
    <ListFilter
      filters={[
        areaFilter,
        categoryFilter,
        companyUnitFilter,
        complianceUsersFilter,
        processesFilter,
        aspectsFilter,
        tagsFilter,
        filterSearch
        //searchFilter
        // complianceIntervalFilter,
      ]}
      setFilter={onSetFilter}
      children={children}
    />
  );
};
