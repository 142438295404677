import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: `${theme.spacing(2)} 0`,
  },
  addButton: {
    marginBottom: theme.spacing(2),
  },

  companyUnitsExceeded: {
    marginBottom: '1em',
  },
  companyUnitFooterContainer: {
    width: '100%',
  },
  companyUnitFooterLabel: {
    paddingLeft: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  companyUnitFooterPagination: {
    position: 'relative',
  },
  topButton: {
    borderRadius: ' 50%',
    position: 'fixed',
    bottom: '10px',
    transform: 'translateX(-44px)',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    display: 'flex',
    //height: '46px',
    opacity: .7,
    width: 50,
    height: 50
  },
}));
