import React, { useContext, useState } from 'react';
import MaterialTable, {
  MaterialTableProps,
  MTableToolbar,
  MTableBodyRow,
  MTableCell,
  MTableHeader,
  Localization,
} from 'material-table';
import { options as defaultOptions } from './options';
import { useIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import { useStyles } from './MTable.styles';
import { UserContext } from '../../Context/UserContext/UserContext';

export const MTable = <RowData extends object>(
  props: MaterialTableProps<RowData> & { actionLabel?: string; hideToolbar?: boolean },
) => {
  const { tableRowCount } = useContext(UserContext);

  const classes = useStyles();
  const intl = useIntl();
  const { options, ...rest } = props;
  const [pageSize, setPageSize] = useState<number>(tableRowCount);

  // Unless overridden in props, retain set page size when re-rendering
  if (rest.onChangeRowsPerPage === undefined) {
    rest.onChangeRowsPerPage = setPageSize;
  }

  return (
    <MaterialTable
      localization={
        {
          pagination: {
            firstTooltip: intl.formatMessage({ id: 'component.mtable.firstTooltip' }),
            labelDisplayedRows: intl.formatMessage({ id: 'component.mtable.labelDisplayedRows' }),
            labelRowsPerPage: intl.formatMessage({ id: 'component.mtable.labelRowsPerPage' }),
            labelRowsSelect: intl.formatMessage({ id: 'component.mtable.labelRowsSelect' }),
            lastTooltip: intl.formatMessage({ id: 'component.mtable.lastTooltip' }),
            nextTooltip: intl.formatMessage({ id: 'component.mtable.nextTooltip' }),
            previousTooltip: intl.formatMessage({ id: 'component.mtable.previousTooltip' }),
          },
          body: {
            addTooltip: intl.formatMessage({ id: 'component.mtable.addTooltip' }),
            dateTimePickerLocalization: {},
            deleteTooltip: intl.formatMessage({ id: 'component.mtable.deleteTooltip' }),
            editRow: intl.formatMessage({ id: 'component.mtable.editRow' }),
            editTooltip: intl.formatMessage({ id: 'component.mtable.editTooltip' }),
            emptyDataSourceMessage: intl.formatMessage({ id: 'component.mtable.emptyDataSourceMessage' }),
            filterRow: intl.formatMessage({ id: 'component.mtable.filterRow' }),
          },
          grouping: {
            groupedBy: intl.formatMessage({ id: 'component.mtable.groupedBy' }),
            placeholder: intl.formatMessage({ id: 'component.mtable.placeholder' }),
          },
          header: {
            actions:
              props.actionLabel !== undefined
                ? props.actionLabel
                : intl.formatMessage({ id: 'component.mtable.actions' }),
          },
          toolbar: {
            addRemoveColumns: intl.formatMessage({ id: 'component.mtable.addRemoveColumns' }),
            exportName: intl.formatMessage({ id: 'component.mtable.exportName' }),
            exportTitle: intl.formatMessage({ id: 'component.mtable.exportTitle' }),
            nRowsSelected: intl.formatMessage({ id: 'component.mtable.nRowsSelected' }),
            searchPlaceholder: intl.formatMessage({ id: 'component.mtable.searchPlaceholder' }),
            searchTooltip: intl.formatMessage({ id: 'component.mtable.searchTooltip' }),
            showColumnsTitle: intl.formatMessage({ id: 'component.mtable.showColumnsTitle' }),
          },
        } as Localization
      }
      components={{
        Toolbar: (props) => <MTableToolbar {...props} classes={{ root: classes.toolbar }} />,
        Row: (props) => <MTableBodyRow {...props} classes={{ root: classes.row }} />,
        Cell: (props) => <MTableCell {...props} classes={{ root: classes.cell }} />,
        Header: (props) => <MTableHeader {...props} classes={{ header: classes.headerCell }} />,
        Container: (props) => <Paper variant="outlined" {...props} />,
      }}
      options={{  ...defaultOptions, toolbar: !props.hideToolbar, ...{ pageSize },...options}}
      {...rest}
    />
  );
};
export default MTable;
