import { IAppConfiguration } from './IAppConfiguration';

export default {
  logrocketKey: undefined,
  aptorApi: 'https://api.test.lagportalen.se',
  clientId: '2c45454e-3eec-48fe-9bb3-87409a59d8a0',
  tenantName: 'aptordev',
  tinyMCEApiKey: 'yyxw0cmmotmephhdqj7ziym91qqnznaq9euzwaf2kk0buvo9',
  redirectUrl: 'https://www.test.lagportalen.se/',
  policyName: 'B2C_1A_signup_signin',
  inivtePolicy: 'B2C_1A_signup_invitation',
  forgotPasswordPolicy: 'B2C_1A_passwordreset',
  redirectLogoutUrl: 'https://www.test.lagportalen.se/',
  tenantId: 'bc1f1324-13bc-42fa-9f39-847d9fed1b9a',
  enableImpersonation: true,
  knownAuthority: 'https://aptordev.b2clogin.com',
  scopes: ['https://aptordev.onmicrosoft.com/d9e7182b-0c50-43f1-b4e2-bd087eeeffc6/user_impersonation'],
} as IAppConfiguration;
