import React, { useCallback, useContext, useEffect } from 'react'
import { Container, HtmlView, MTable, RepealableName } from '../../../../../../Components'
import { useIntl } from 'react-intl'
import { useAptorApi } from '../../../../../../Api';
import { useFilter } from '../../../../../../Components/ListFilter';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { Query } from 'material-table';
import {  Checkbox } from '@material-ui/core';
import { ComplianceFilter } from "./ComplianceFilter";
import { useComplianceState } from "./ComplianceState";
import { IGridQueryRequest, OrderDirection } from "../../../../../../Api/AptorApi";
import { useStyles } from '../../../../LawPortal.styles';
import { ModuleContext } from '../../../../../../Context/ModuleContext/ModuleContext';
type LawData = {
  id: number;
  number: string;
  name: string;
  changeEffectiveFrom?: Date;
  changeNumber?: string;
  effectiveTo?: Date;
  tags?: string;
};
interface IProps {
  selectedRows:any
  setSelectedRows:any
}
export const ComplianceTable = ({selectedRows,setSelectedRows }: IProps) => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();
  const classes = useStyles();
  const {
    enableCompliance,
    isComplianceUserFor,
  } = useComplianceState();
  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);
  const { setHeader } = useContext(ModuleContext);
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }), [
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.lek' }),
        to: `/law-portal/legal-compliance-control/planning`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
        to: `/law-portal/legal-compliance-control/carry`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
        to: `/law-portal/legal-compliance-control/reports`,
      },
    ]);
  }, [setHeader, formatMessage]);
  function handleRowSelect(id: any) {

    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId: any) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  }
  const handleDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLawPortalLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  // const renderDate = (date?: Date) => {
  //   return date ? moment(date).format('ll') : undefined;
  // };
  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);


  return (
    <div className={classes.listWrapper}>
      <Container fullWidth>
        <ComplianceFilter setFilter={setFilter} filters={filters} >
          {/* <ComplianceActions
            complianceEnabled={complianceEnabled}
            enableCompliance={enableCompliance}
            disableCompliance={disableCompliance}
            hasCompliance={hasCompliance}
            startComplianceSession={startComplianceSession}
          /> */}
        </ComplianceFilter>
        {/* <Grid container justify='flex-end' alignItems='center' style={{ marginTop: 20 }}><Button onClick={() => addLaws()} disabled={selectedRows.length > 0 ? false : true} color="primary" variant="outlined">{formatMessage({ id: 'law-portal.legal.plane.save' })}</Button></Grid> */}
        <MTable
          tableRef={tableRef}
          columns={[
            { title: formatMessage({ id: 'admin.law-portal.table.number' }), field: 'number' },
            {
              title: formatMessage({ id: 'admin.law-portal.table.law' }),
              field: 'name',
              render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
            },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.description' }),
              field: 'description',
              render: (row: any) =>  <HtmlView variant="body2" value={row.description} />,
              sorting: false,
            },
            { title: formatMessage({ id: 'law-portal.our-laws.table.area' }), field: 'area' },
            {
              title: formatMessage({ id: 'law-portal.our-laws.table.notes' }),
              field: 'notes',
              sorting: false,
            },
            // {
            //   title: 'Select', // Add a selection column
            //   field: 'select',
            //   render: (rowData: any) => (
            //     <Checkbox checked={selectedRows.includes(rowData.id)}
            //       onChange={() => handleRowSelect(rowData.id)} />
            //   ),
            // },
          ]}
          data={handleDataFetch}
          // onRowClick={(_, row) => row && history.push(`/law-portal/our-laws/${row.id}`)}
          actionLabel={enableCompliance ? formatMessage({ id: 'law-portal.our-laws.table.compliance' }) : undefined}
          actions={
            [
              (row: LawData) => ({
                icon: () => <Checkbox checked={selectedRows.includes(row.id)} />,
                onClick: () => handleRowSelect(row.id),
                hidden: !isComplianceUserFor(row.id),
              }),
            ]
          }
          components={{}}
        />
      </Container>
    </div>
  )
}

