import React, { useEffect, useState } from 'react';
import { Grid,Button,Tooltip } from '@material-ui/core';
import { LawSettingsAreas } from './Areas';
import { LawSettingsCategories } from './Categories';
import { LawSettingsTags } from './Tags';
import { Container } from '../../../../../Components';
import {ArrowUpward} from '@material-ui/icons'
import { useStyles } from "../../../AptorAdminModule.styles";
import { useIntl } from "react-intl";
export const LawSettings = () => {
  const classes=useStyles()
  const [showScrollTop, setShowScrollTop] = useState(false);
  const {formatMessage}=useIntl()
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Container>
        <Grid container spacing={4} direction="column" justify="space-evenly">
          <Grid item>
            <LawSettingsAreas />
          </Grid>
          <Grid item>
            <LawSettingsCategories />
          </Grid>
          <Grid item>
            <LawSettingsTags />
          </Grid>
        </Grid>
      </Container>
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
