import { makeStyles, Theme } from '@material-ui/core/styles';

export const sidebarWidth = 19;
export const miniSidebarWidth = 4;
const drawerHeaderHeight = 4;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: `${sidebarWidth}rem`,
    flexShrink: 0,
  },
  open: {
    width: `${sidebarWidth}rem`,
  },
  close: {
    overflowX: 'hidden',
    width: `${miniSidebarWidth}rem`,
  },
  sidebar: {
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${drawerHeaderHeight}rem)`,
    },
    height: '100%',
  },
  sidebarOpen: {
    flexWrap: 'nowrap', //To avoid logo from wrapping when screen is shorter than sidebar
  },
  title: {
    textTransform: 'uppercase',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));
