import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid,Button,Tooltip } from '@material-ui/core';
import { AssignmentTurnedIn } from '@material-ui/icons';
import { Loading, EditablePanel, HtmlView, HtmlForm, LawLink, Container, BackButton } from '../../../../../Components';
import { ButtonLink } from '../../../../../Components/ButtonLink/ButtonLink';
import { EditLawInformationForm } from './Information.Form';
import { EditLawInformationView } from './Information.View';
import { ReplaceLawView, ReplaceLawForm } from './ReplaceLaw';
import { EditLawRelatedLaws } from './RelatedLaws';
import { EditLawChangesView } from './Changes.View';
import { EditLawAddChange } from './Changes.Form';
import { useOptions } from '../Options';
import { AptorApi, useAptorApi } from '../../../../../Api';
import { INamedEntity } from '../../../../../Api/AptorApi';
import { IChange } from '../../../../../Components/LawChange';
import { useStyles } from "../../../AptorAdminModule.styles";
import {ArrowUpward} from '@material-ui/icons'
export interface ILawChange extends IChange {
  isPosibleToDelete: boolean;
}

interface ILaw {
  name: string;
  number: string;
  shortDescription: string;
  chapter: string;
  application: string;
  effectiveFrom: Date;
  effectiveTo?: Date;
  area: INamedEntity;
  category: INamedEntity;
  tags: INamedEntity[];
  country: INamedEntity;
  replacesLaw?: INamedEntity;
  link: { url: string; text?: string };
  changes: ILawChange[];
  checklistId?: number;
}

export const EditLaw = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const [options] = useOptions();
  const { api, abortController } = useAptorApi();
  const [revision, setRevision] = useState<number>(0);
  const [law, setLaw] = useState<ILaw>();
  const { formatMessage } = useIntl();
  const classes=useStyles()
  useEffect(() => {
    api.getLaw<ILaw>(id).then((response) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setLaw(response);
    });
  }, [api, abortController, id]);

  const updateLawState = (update: Partial<ILaw>) => {
    if (law) {
      setLaw({ ...law, ...update });
      setTimeout(() => setRevision(revision + 1), 250);
    }
  };

  const deleteLawReplacement = async () => {
    await api.replaceLaw(+id, null);
    updateLawState({ replacesLaw: undefined });
  };

  const submitLawReplacement = async (value: INamedEntity, aptorApi: AptorApi) => {
    await aptorApi.replaceLaw(+id, value.id);
    updateLawState({ replacesLaw: value });
  };

  const submitShortDescription = async (value: string, aptorApi: AptorApi, onSuccess: () => void) => {
    await aptorApi.updateLawShortDescription(id, value);
    onSuccess();
    updateLawState({ shortDescription: value });
  };

  const submitChapter = async (value: string, aptorApi: AptorApi, onSuccess: () => void) => {
    await aptorApi.updateLawChapter(id, value);
    onSuccess();
    updateLawState({ chapter: value });
  };

  const submitApplication = async (value: string, aptorApi: AptorApi, onSuccess: () => void) => {
    await aptorApi.updateLawApplication(id, value);
    onSuccess();
    updateLawState({ application: value });
  };

  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (options === null || law === undefined) {
    return <Loading />;
  }

  return (
   <>
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <BackButton disableMargin link="/admin/law-portal/laws" />
            </Grid>
            <Grid item>
              <ButtonLink
                to={`/admin/law-portal/laws/${id}/checklist`}
                text={formatMessage({
                  id: 'admin.law-portal.law.form.checklist.edit.button',
                  defaultMessage: 'Edit checklist',
                })}
                icon={<AssignmentTurnedIn />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.information.label"
            dependencies={[revision]}
            view={<EditLawInformationView {...law} />}
            form={<EditLawInformationForm options={options} id={id} {...law} onChange={updateLawState} />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.replacesLaw.label"
            dependencies={[revision]}
            view={
              <ReplaceLawView
                replacedLaw={law.replacesLaw ? law.replacesLaw.name : undefined}
                submit={deleteLawReplacement}
              />
            }
            form={<ReplaceLawForm id={+id} submit={submitLawReplacement} />}
          />
        </Grid>
        <Grid item xs={12}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.description.label"
            dependencies={[revision]}
            view={
              <>
                <HtmlView value={law.shortDescription} />
                <LawLink type="law-description" url={law.link.url} urlText={law.link.text} />
              </>
            }
            form={
              <HtmlForm
                propertyNameTranslationKey="admin.law-portal.law.form.description.label"
                value={law.shortDescription}
                submit={submitShortDescription}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <EditablePanel
            iconType="add"
            titleKey="admin.law-portal.law.form.latest-change.label"
            dependencies={[revision]}
            view={<EditLawChangesView id={id} changes={law.changes} onChange={updateLawState} />}
            form={<EditLawAddChange id={id} changes={law.changes} onChange={updateLawState} />}
          />
        </Grid>
        <Grid item xs={12}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.chapter.label"
            dependencies={[revision]}
            view={<HtmlView value={law.chapter} />}
            form={
              <HtmlForm
                propertyNameTranslationKey="admin.law-portal.law.form.chapter.label"
                value={law.chapter}
                submit={submitChapter}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.application.label"
            dependencies={[revision]}
            view={
              <>
                <LawLink variant="caption" type="law-application" url={law.link.url} urlText={law.link.text} />
                <HtmlView value={law.application} />
              </>
            }
            form={
              <HtmlForm
                propertyNameTranslationKey="admin.law-portal.law.form.application.label"
                value={law.application}
                submit={submitApplication}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <EditablePanel
            titleKey="admin.law-portal.law.form.related-laws.label"
            dependencies={[revision]}
            view={<EditLawRelatedLaws id={id} key={id} />}
            form={<EditLawRelatedLaws id={id} formOn />}
          />
        </Grid>
      </Grid>
    </Container>
    {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
   </>
  );
};
