import React, { FC, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Query } from 'material-table';
import { Grid } from '@material-ui/core';
import { MTable } from '../../../../Components';
import { ButtonLink } from '../../../../Components/ButtonLink/ButtonLink';
import { UserContext } from '../../../../Context/UserContext/UserContext';
import { INamedEntity, IGridQueryResult, IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { useStyles } from '../Organization/Organization.styles';

export interface ICompanyUnit extends INamedEntity {
  users: string;
}

interface IProps {
  addLink: string;
  dataFetcher: (request: IGridQueryRequest) => Promise<IGridQueryResult<ICompanyUnit> | null>;
  edit: (id: number) => void;
  remove: (id: number) => Promise<void>;
}
export const CompanyUnits: FC<IProps> = ({ addLink, dataFetcher, edit, remove }) => {
  const tableRef = React.createRef();
  const { formatMessage } = useIntl();
  const { getCompanyUnitName } = useContext(UserContext);
  const classes = useStyles();

  const handleDataFetch = useCallback(
    async (query: Query<ICompanyUnit>) => {
      return (
        (await dataFetcher({
          orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
          orderBy: query.orderBy !== undefined ? query.orderBy.field : 'name',
          page: query.page,
          pageSize: query.pageSize,
          search: query.search,
        } as IGridQueryRequest)) ?? { data: [], page: 0, totalCount: 0 }
      );
    },
    [dataFetcher],
  );

  const editCU = (unit: ICompanyUnit) => ({
    tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
    icon: 'edit',
    onClick: () => edit(unit.id),
  });

  const removeCU = (unit: ICompanyUnit) => ({
    tooltip: formatMessage({ id: 'component.mtable.deleteTooltip' }),
    icon: 'delete',
    onClick: async () => {
      await remove(unit.id);
      (tableRef.current as any).onQueryChange();
    },
  });

  return (
    <>
      <Grid container justify="flex-end">
        <ButtonLink
          className={classes.addButton}
          to={addLink}
          text={formatMessage(
            { id: 'law-portal.manage.edit-law.addCompanyUnit' },
            { alias: getCompanyUnitName(formatMessage, true) },
          )}
        />
      </Grid>
      <MTable
        // hideToolbar
        tableRef={tableRef}
        columns={[
          { title: getCompanyUnitName(formatMessage), field: 'name' },
          {
            title: formatMessage({ id: 'law-portal.manage.edit-law.complianceUsers' }),
            field: 'users',
            sorting: false,
          },
        ]}
        data={handleDataFetch}
        actions={[editCU, removeCU]}
        components={{}}
      />
    </>
  );
};
