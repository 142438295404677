import React, { FC, memo, useCallback, useState } from 'react';
import clsx from 'clsx';
import { ListItemText, ListItem, ListItemSecondaryAction, IconButton, Tooltip, Button, TextField } from '@material-ui/core';
import { Delete, VisibilityOff, Visibility, DragIndicator, Edit } from '@material-ui/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStyles } from './Checklist.Question.styles';

interface IQuestionBaseProps {
  name: string;
  hidden?: boolean;
  action: React.ReactNode;
  index?:any;
  editValue?:any;
  setEditValue?:any;
  edit?:any
}

const QuestionBase: FC<IQuestionBaseProps> = memo(({ index,name, hidden, action,editValue ,setEditValue,edit}) => {
  const classes = useStyles();
  return (
    <ListItem className={clsx(classes.root, { [classes.hidden]: hidden })} classes={{ container: classes.container }}>
      <ListItemText>{index+1&&<span style={{fontWeight:'bold',marginRight:"5px"}}>{index+1}.</span>}<span>{edit ? <TextField style={{width:"90%"}} value={editValue} onChange={(e) => setEditValue(e.target.value)} />:name}</span></ListItemText>
      <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
    </ListItem>
  );
});

interface ILawQuestionProps {
  id: number;
  name: string;
  hidden: boolean;
  toggleHidden: (questionId: number, groupId: number | undefined, hidden: boolean) => void;
  index:any
}

export const LawQuestion: FC<ILawQuestionProps> = memo(({ id, name, hidden, toggleHidden ,index}) => {
  const { formatMessage } = useIntl();
  const buttonTranslationKey = hidden
    ? 'law-portal.manage.checklist.question.show-button'
    : 'law-portal.manage.checklist.question.hide-button';
  const buttonIcon = hidden ? <VisibilityOff /> : <Visibility />;
  const onToggleHidden = useCallback(() => toggleHidden(id, undefined, !hidden), [id, hidden, toggleHidden]);

  return (
    <QuestionBase
      name={name}
      hidden={hidden}
      index={index}
      action={
        <Tooltip title={formatMessage({ id: buttonTranslationKey })}>
          <IconButton onClick={onToggleHidden} aria-label={formatMessage({ id: buttonTranslationKey })}>
            {buttonIcon}
          </IconButton>
        </Tooltip>
      }
    />
  );
});

interface ICustomerQuestionProps {
  index: number;
  name: string;
  remove: (index: number) => void;
  editItem:(value:any,index:number)=>void
}

export const CustomerQuestion: FC<ICustomerQuestionProps> = memo(({ index, name, remove,editItem }) => {
  const { formatMessage } = useIntl();
  const onRemove = useCallback(() => remove(index), [index, remove]);
const [edit,setEdit]=useState(false)
const [editValue,setEditValue]=useState('')

  const secondaryAction = (
    <ListItemSecondaryAction>
      {edit ? (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            color="secondary"
            onClick={() => {
              setEdit(false);
              editItem(editValue,index);
            }}
          >
            <FormattedMessage id="form.save" defaultMessage="Save" />
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setEdit(false);
              setEditValue('');
            }}
          >
            <FormattedMessage id="form.cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      ) : (
        <div style={{display:'flex',alignItems:'center'}}>
          <Tooltip title={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}>
            <IconButton
              onClick={() => {
                setEdit(true);
                setEditValue(name);
              }}
              aria-label={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
            <IconButton
              onClick={onRemove}
              aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={formatMessage({ id: 'admin.law-portal.law.checklist.list.drag', defaultMessage: 'Drag' })}
          >

              <DragIndicator />
          </Tooltip>
        </div>
      )}
    </ListItemSecondaryAction>
  );
  return (
    <QuestionBase
      name={name}
      index={index}
      action={secondaryAction}
      edit={edit}
      editValue={editValue}
      setEditValue={setEditValue}
    />
  );
});
