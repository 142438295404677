import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Header } from '../../Components';
import { AdministrationSidebar } from './Components/AdministrationSidebar';
import { Organization } from './Features/Organization';
import { LawPortal } from './Features/LawPortal';
import { ModuleContext, useModule } from '../../Context/ModuleContext/ModuleContext';
import { useStyles } from '../Module.styles';
import clsx from 'clsx';
import { Help } from "./Features/Help";

export const AdministrationModule = () => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const handleSidebarToggle = useCallback((open: boolean) => {
    setSidebarOpen(open);
  }, []);
  const { Provider } = ModuleContext;
  const module = useModule();
  return (
    <Grid container>
      <Grid item>
        <AdministrationSidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle} />
      </Grid>
      <Grid
        item
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <Provider value={module}>
          <Header
            sidebarOpen={sidebarOpen}
            handleSidebarToggle={handleSidebarToggle}
            title={module.header}
            subMenu={module.subMenu}
          />
          <Switch>
            <Route path="/organization/law-portal" component={LawPortal} />
            <Route path="/organization/help" component={Help} />
            <Route path="/organization" component={Organization} />
          </Switch>
        </Provider>
      </Grid>
    </Grid>
  );
};
export default AdministrationModule;
