import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  List,
  ListSubheader,
  Drawer,
  Hidden,
  IconButton,
  Tooltip,
  useMediaQuery,
  Grid,
} from '@material-ui/core';
import logo from '../../Assets/Images/aptor_logo.svg';
import { IListItemLinkProps, ListItemLink } from '../ListItemLink/ListItemLink';
import { Logo } from '../Logo/Logo';
import { useIntl } from 'react-intl';
import { Home, ChevronLeft, ChevronRight } from '@material-ui/icons';
// import { getAuthProvider } from '../../Auth/AuthProvider';
import { useStyles } from './Sidebar.styles';
import { useAptorApi } from '../../Api';
import { useLocation } from 'react-router-dom';
import { AccessLevel, LawPortalFeature, Module, UserContext } from "../../Context/UserContext/UserContext";

const LOGO_KEY = 'logo_';

export interface ISidebarProps {
  title?: React.ReactNode;
  handleSidebarToggle: (show: boolean) => void;
  sidebarOpen: boolean;
  items?: IListItemLinkProps[];
}

const getInitialIndex = (currentPath: string, items?: IListItemLinkProps[]) => {
  if (!items) {
    return 0;
  } else {
    const matches = items
      .filter((x) => currentPath.indexOf(x.to) > -1)
      .sort((a, b) => (a.to.length > b.to.length ? -1 : 1));

    return matches.length === 0 ? 0 : items.indexOf(matches[0]);
  }
};

export const Sidebar: React.FC<ISidebarProps> = (props) => {
  const location = useLocation();
  const { title, handleSidebarToggle, sidebarOpen, items } = props;
  const { api, abortController } = useAptorApi();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState<number>(getInitialIndex(location.pathname, props.items));
  const isMobile = useMediaQuery((theme) => (theme as any).breakpoints.down('md'));
  const { hasAccessToModule, hasAccessToFeature } = useContext(UserContext);
  useEffect(() => {
    if (isMobile) {
      handleSidebarToggle(false);
    } else {
      handleSidebarToggle(true);
    }
  }, [isMobile, handleSidebarToggle]);

  const [organizationLogo, setOrganizationLogo] = useState<string | undefined>(
    localStorage.getItem(LOGO_KEY) ?? undefined,
  );

  useEffect(() => {
    setSelectedIndex(getInitialIndex(location.pathname, items));
  }, [location, items]);

  useEffect(() => {
    const fetchLogotype = async () => {
      const logo = await api.getLogotype();
      if (logo) {
        localStorage.setItem(LOGO_KEY, logo);
      } else {
        localStorage.removeItem(LOGO_KEY);
      }
      setOrganizationLogo(logo);
    };

    fetchLogotype();
  }, [api, abortController]);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
    if (isMobile) {
      handleSidebarToggle(false);
    }
  };

  // const handleLogout = () => {
  //   const authProvider = getAuthProvider();
  //   authProvider.logout();
  // };

  const sidebar = (
    <Grid
      className={clsx(classes.sidebar, { [classes.sidebarOpen]: sidebarOpen })}
      container
      direction="column"
      justify="space-between"
    >
      <Grid item>
        {sidebarOpen && organizationLogo && <Logo src={organizationLogo} alt="Organization logo" />}
        <List component="nav" aria-label="sidebar navigation">
          {sidebarOpen && (
            <ListSubheader className={classes.title} component="span">
              {title}
            </ListSubheader>
          )}
          {/* {hasAccessToModule(Module.LawPortal) && !hasAccessToModule(Module.Administration) && !(hasAccessToModule(Module.Organization) ||
            hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Read)) ? "" : <ListItemLink
            to="/"
            text={formatMessage({ id: 'component.sidebar.start', defaultMessage: 'Start' })}
            icon={<Home />}
            onClick={() => sessionStorage.clear()}
          />} */}
           {hasAccessToModule(Module.Administration) && <ListItemLink
            to="/"
            text={formatMessage({ id: 'component.sidebar.start', defaultMessage: 'Start' })}
            icon={<Home />}
            onClick={() => sessionStorage.clear()}
          />}
          {items?.map((item, index) => (
            <ListItemLink
              key={index}
              to={item.to}
              text={item.text}
              selected={selectedIndex === index}
              icon={item.icon}
              onClick={() => handleClick(index)}
            />
          ))}
          {/* <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <MeetingRoom />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                id="component.sidebar.logout"
                defaultMessage="Log out"
                description="Label for Logout-button"
              />
            </ListItemText>
          </ListItem> */}
        </List>
      </Grid>
      {sidebarOpen && (
        <Grid item>
          <Logo src={logo} alt="Aptor logo" small />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {/* Desktop */}
      <Hidden mdDown>
        <Drawer
          className={clsx(classes.root, { [classes.open]: sidebarOpen, [classes.close]: !sidebarOpen })}
          classes={{ paper: clsx({ [classes.open]: sidebarOpen, [classes.close]: !sidebarOpen }) }}
          variant="permanent"
          open={sidebarOpen}
        >
          <div className={classes.drawerHeader}>
            {sidebarOpen ? (
              <Tooltip title={formatMessage({ id: 'component.sidebar.hide', defaultMessage: 'Hide menu' })}>
                <IconButton
                  onClick={() => handleSidebarToggle(false)}
                  aria-label={formatMessage({ id: 'component.sidebar.hide', defaultMessage: 'Hide menu' })}
                >
                  <ChevronLeft />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={formatMessage({ id: 'component.sidebar.show', defaultMessage: 'Show menu' })}>
                <IconButton
                  onClick={() => handleSidebarToggle(true)}
                  aria-label={formatMessage({ id: 'component.sidebar.show', defaultMessage: 'Show menu' })}
                >
                  <ChevronRight />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {sidebar}
        </Drawer>
      </Hidden>

      {/* Tablet & Mobile */}
      <Hidden lgUp>
        <Drawer
          classes={{ paper: classes.root }}
          variant="temporary"
          open={sidebarOpen}
          onClose={() => handleSidebarToggle(false)}
        >
          {sidebar}
        </Drawer>
      </Hidden>
    </>
  );
};
