import { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
// import { useComplianceSession } from '../../../../../../Context/ComplianceSessionContext';
import { ModuleContext } from '../../../../../../Context/ModuleContext/ModuleContext';
import { useIntl } from 'react-intl';

interface ILawData {
  id: number;
  name: string;
}

export const useComplianceState = () => {
  // const history = useHistory();
  // const { createComplianceSession } = useComplianceSession();
  const { complianceUserFor } = useContext(UserContext);
  const { setHeader } = useContext(ModuleContext);
  const { formatMessage } = useIntl();
  const [lawsSelectedForCompliance, setLawsSelectedForCompliance] = useState<ILawData[]>([]);
  const [enabled, setEnabled] = useState<boolean>(false);
  const hasCompliance = (predicate: (law: ILawData) => boolean) => lawsSelectedForCompliance.some(predicate);

  const toggleCompliance = (row: ILawData) => {
    if (hasCompliance((x) => x.id === row.id)) {
      setLawsSelectedForCompliance(lawsSelectedForCompliance.filter((x) => x.id !== row.id));
    } else {
      setLawsSelectedForCompliance([...lawsSelectedForCompliance, row]);
    }
  };

  const startComplianceSession = () => {
    if (lawsSelectedForCompliance.length > 0) {
      // const session = createComplianceSession(lawsSelectedForCompliance);
      // setEnabled(false);
      // setLawsSelectedForCompliance([]);
      // history.push(session.todo[0].complianceUrl);
    }
  };
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }));
  }, [setHeader, formatMessage]);
  return {
    enableCompliance: complianceUserFor.laws.length > 0 ? () => setEnabled(true) : undefined,
    disableCompliance: () => setEnabled(false),
    complianceEnabled: enabled,
    toggleCompliance,
    startComplianceSession,
    hasCompliance,
    isComplianceUserFor: (lawId: number) => complianceUserFor.laws.some((x) => x.id === lawId),
    setLawsSelectedForCompliance: (laws: any) => setLawsSelectedForCompliance(laws),
  };
};
