import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container } from '../../../../Components/Container/Container';
import { Query } from 'material-table';
import MTable from '../../../../Components/MTable/MTable';
import { IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { useAptorApi } from '../../../../Api';
import moment from 'moment';
import { SupervisorAccountOutlined } from '@material-ui/icons';
import { Tooltip, Grid, Button } from '@material-ui/core';
import { useStyles } from "../../AptorAdminModule.styles";
import { ArrowUpward } from '@material-ui/icons'

export type AuditItem = {
  id: number;
  type: 'create' | 'update' | 'delete';
  entityId: number;
  entityName: string;
  userId: number;
  userName: string;
  impersonatorName?: string;
  createdDateUtc: Date;
};

export const AuditList = () => {
  const intl = useIntl();
  const history = useHistory();
  const { api } = useAptorApi();
  const tableRef = React.createRef();
  const classes = useStyles()
  const handleDataFetch = useCallback(
    async (query: Query<AuditItem>) => {
      return await api.searchAudits<AuditItem>({
        orderDirection: query.orderDirection === 'asc' ? OrderDirection.Ascending : OrderDirection.Descending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'createdDateUtc',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
      } as IGridQueryRequest);
    },
    [api],
  );

  const renderDateTime = (date?: Date) => {
    return date ? moment(date).format('lll') : undefined;
  };

  const renderUser = (item: AuditItem) => {
    if (item.impersonatorName) {
      return (
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <span>{item.userName}</span>
          </Grid>
          <Tooltip title={item.impersonatorName}>
            <SupervisorAccountOutlined />
          </Tooltip>
        </Grid>
      );
    }
    return <span>{item.userName}</span>;
  };
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Container fullWidth>
        <MTable
          tableRef={tableRef}
          columns={[
            {
              title: intl.formatMessage({ id: 'admin.audit.table.entityName' }),
              field: 'entityName',
            },
            {
              title: intl.formatMessage({ id: 'admin.audit.table.type' }),
              field: 'type',
              sorting: false
            },
            {
              title: intl.formatMessage({ id: 'admin.audit.table.user' }),
              field: 'userName',
              render: renderUser,
            },
            {
              title: intl.formatMessage({ id: 'admin.audit.table.createdDate' }),
              field: 'createdDateUtc',
              render: (row) => renderDateTime(row.createdDateUtc),
            },
          ]}
          data={handleDataFetch}
          onRowClick={(_, row) => row && history.push(`/admin/audit/${row.id}`)}
          components={{}}
        />
      </Container>
      {showScrollTop && (
        <Tooltip title={intl.formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
