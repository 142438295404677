import { Grid, IconButton, InputBase } from '@material-ui/core';
// import moment from 'moment';
import React, { FC,
  //  useEffect,
  //   useState 
  } from 'react';
// import { useIsMounted } from '../../Hooks/useIsMounted';
import { CloseOutlined, Search } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { useStyles } from "../Field/Field.styles";

type FilterValueType = string;
type OnSetFilter = (field: string, values: FilterValueType) => void;

interface ISearchFilterProps {
  name: any;
  value: any;
  onChange: any
}

export interface ISearchFilter {
  type: 'search';
  name: string;
  onChange: (value: any) => void;
  value: any
}

export const SearchFilter: FC<ISearchFilterProps> = ({ name, onChange, value }) => {
  // const isMounted = useIsMounted();
  const { formatMessage } = useIntl()
  const classes = useStyles();
  // useEffect(() => {

  //   debugger 
  //   if (isMounted) {
  //     setFilter(filter.name, search || undefined);
  //   }
  // }, [search, setFilter, filter.name, isMounted]);
  return (
    <>
      <Grid item md={3} sm={6} xs={12}>
        <div
          className={classes.searchRoot}
          style={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <Search className={classes.searchIcon} />
          <InputBase
            name={name}
            placeholder={formatMessage({
              id: "component.mtable.searchPlaceholder",
              defaultMessage: "Search",
            })}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
          {value!==""&&<IconButton
            onClick={()=>onChange("")}
            style={{ position: "absolute", right: 0 }}
          >
            <CloseOutlined className={classes.closeSearch} />
          </IconButton>}
        </div>
      </Grid>
    </>
  );
};
