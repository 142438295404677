import React, { useCallback, useEffect,useState } from 'react';
import MTable from '../../../../Components/MTable/MTable';
import { useIntl } from 'react-intl';
import { Container } from '../../../../Components/Container/Container';
import { useAptorApi } from '../../../../Api';
import { Query } from 'material-table';
import { IGridQueryRequest, OrderDirection } from '../../../../Api/AptorApi';
import { LawListFilter } from './List.Filter';
import { useHistory, useLocation } from 'react-router-dom';
import { useFilter } from '../../../../Components/ListFilter';
import moment from 'moment';
import { RepealableName } from '../../../../Components';
import { useStyles } from "../../AptorAdminModule.styles";
import {Button,Tooltip} from '@material-ui/core'
import {ArrowUpward} from '@material-ui/icons'

type LawData = {
  id: number;
  number: string;
  name: string;
  changeEffectiveFrom?: Date;
  changeNumber?: string;
  effectiveTo?: Date;
  tags?: string;
};

export const LawTable = () => {
  const { formatMessage } = useIntl();
  const { api } = useAptorApi();
  const [filters, setFilter] = useFilter();
  const tableRef = React.createRef();
  const history = useHistory();
  const classes=useStyles()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  }, [filters, tableRef]);
  useEffect(() => {
    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'area',
      tags:'tags'
    };
    if (location.search?.split('&')?.length > 1) {
      Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
        const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
      });
    } else {
      let saveFilter: any = localStorage.getItem('admin-law')
      const queryParams = new URLSearchParams(saveFilter);
      Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
        const queryValues = queryParams.getAll(queryKey);
        if (queryValues.length) {
          setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
        }
      });

    }
  }, []);
  const handleDataFetch = useCallback(
    async (query: Query<LawData>) => {
      return await api.searchLaws<LawData>({
        orderDirection: query.orderDirection === 'desc' ? OrderDirection.Descending : OrderDirection.Ascending,
        orderBy: query.orderBy !== undefined ? query.orderBy.field : 'number',
        page: query.page,
        pageSize: query.pageSize,
        search: query.search,
        filters: filters,
      } as IGridQueryRequest);
    },
    [api, filters],
  );

  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };

  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
      localStorage.setItem("admin-law", updatedQueryParams)
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history]);

  return (
    <>
    <Container fullWidth linkButtonTo="/admin/law-portal/laws/new" linkButtonLabel="admin.law-portal.add-law">
      <LawListFilter filters={filters} setFilter={setFilter} />
      <MTable
        tableRef={tableRef}
        columns={[
          { title: formatMessage({ id: 'admin.law-portal.table.number' }), field: 'number' },
          {
            title: formatMessage({ id: 'admin.law-portal.table.law' }),
            field: 'name',
            render: (row) => <RepealableName name={row.name} effectiveTo={row.effectiveTo} />,
          },
          { title: formatMessage({ id: 'admin.law-portal.table.changeNumber' }), field: 'changeNumber' },
          {
            title: formatMessage({ id: 'admin.law-portal.table.changeDate' }),
            field: 'changeEffectiveFrom',
            render: (row) => renderDate(row.changeEffectiveFrom),
          },
          { title: formatMessage({ id: 'admin.law-portal.table.tags' }), field: 'tags', sorting: false },
        ]}
        data={handleDataFetch}
        actions={[
          (row) => ({
            tooltip: formatMessage({ id: 'component.mtable.editTooltip' }),
            icon: 'edit',
            onClick: () => history.push(`/admin/law-portal/laws/${row.id}`),
          }),
        ]}
        components={{}}
      />
    </Container>
    {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}

    </>
  );
};
