import React, { useContext } from 'react';
import { IListItemLinkProps } from '../../../../Components/ListItemLink/ListItemLink';
import { Sidebar, ISidebarProps } from '../../../../Components/Sidebar/Sidebar';
import {
  Dashboard,
  Gavel,
  FormatListBulleted,
  // BarChart,
  // Settings,
  // LibraryAddCheck,
  DoneAll,
  // PlaylistAddCheck,
  LiveHelp,
  SupervisedUserCircle,
  BarChart
} from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { AccessLevel, LawPortalFeature, Module, UserContext } from '../../../../Context/UserContext/UserContext';


export const LawPortalSidebar = (props: ISidebarProps) => {
  const { hasAccessToFeature ,hasAccessToModule} = useContext(UserContext);
  const accessToOurLaws = hasAccessToFeature(LawPortalFeature.OurLaws, AccessLevel.Read);
  const accessToOtherRequirements = hasAccessToFeature(LawPortalFeature.OtherRequirements, AccessLevel.Read);
  const { formatMessage } = useIntl();

  const items: IListItemLinkProps[] = [];
  items.push({
    to: '/law-portal/dashboard',
    text: formatMessage({ id: 'law-portal.dashboard.header', defaultMessage: 'Dashboard' }),
    icon: <Dashboard />,
  });

  if (accessToOurLaws) {
    items.push({
      to: '/law-portal/our-laws',
      text: formatMessage({ id: 'law-portal.our-laws.header', defaultMessage: 'Our laws' }),
      icon: <Gavel />,
    });
  }
  if (accessToOtherRequirements) {
    items.push({
      to: '/law-portal/other-requirements',
      text: formatMessage({ id: 'law-portal.requirements.header', defaultMessage: 'Other Requirements' }),
      icon: <FormatListBulleted />,
    });
  }
  items.push({
    to: '/law-portal/legal-compliance-control',
    text: formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Compliance Control' }),
    icon: <DoneAll />,
  });
  //  items.push({
  //   to:'/law-portal/compliance-control-requirements',
  //   text:formatMessage({id:"law-portal.legal.requirement.header",defaultMessage:'Compliance control requirement'}),
  //   icon:<PlaylistAddCheck/>,
  //  })
  items.push({
    to: '/law-portal/statistics',
    text: formatMessage({ id: 'law-portal.statistics.header', defaultMessage: 'Statistics' }),
    icon: <BarChart />,
  });
  // items.push({
  //   to: '/law-portal/settings',
  //   text: formatMessage({ id: 'law-portal.settings.header', defaultMessage: 'Settings' }),
  //   icon: <Settings />,
  // });
  // items.push({
  //   to: '/law-portal/administration',
  //   text: formatMessage({ id: 'component.home.administration', defaultMessage: 'Administration' }),
  //   icon: <SupervisedUserCircle />,
  // });

  if((hasAccessToModule(Module.Organization) ||
  hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Read))){
    items.push({
      to: '/organization',
      text: formatMessage({ id: 'component.home.administration', defaultMessage: 'Administration' }),
      icon: <SupervisedUserCircle />,
    });
  }
  items.push({
    to: '/law-portal/help',
    text: formatMessage({ id: 'law-portal.help', defaultMessage: 'Help' }),
    icon: <LiveHelp />,
  });
  return (
    <Sidebar
      title={formatMessage({ id: 'component.home.law-portal', defaultMessage: 'Lawportal' })}
      items={items}
      sidebarOpen={props.sidebarOpen}
      handleSidebarToggle={props.handleSidebarToggle}
    />
  );
};
